import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../components';
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { currentMonthStartEnd } from '../../../helpers'

export const loadAllAppointments = createAsyncThunk('Appointments',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingAppointments())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body:JSON.stringify(data)
        };
        fetch(domainUrl + `/filter`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(appointmentsResponse(result?.result?.reverse()?.map(data=>({...data,recipentArea:JSON.parse(data?.recipentArea),treatmentTypeIDs:JSON.parse(data?.treatmentTypeIDs)}))))
                else
                {
                    dispatch(appointmentsClear())
                    throw  'error'
                }
            })
            .catch(error => {dispatch(appointmentsClear()); Alert("error","error while loading appointments")})
    }
)
const AppointmentsSlice = createSlice({
    name: 'allAppointments',
    initialState: {
        appointments: [],
        loadingAppointments: false,
        filters:{
            name:'',
            treatmentTypeIDs:[1, 2, 3],
            appointmentDate: currentMonthStartEnd(),
            loadAllAppointments:1,
        }
    },
    reducers: {
        gettingAppointments: (state, action) => {
            state.loadingAppointments = true;
        },
        appointmentsResponse: (state, action) => {
            state.appointments = action.payload;
            state.loadingAppointments = false;
        },
        appointmentsClear: (state, action) => {
            state.appointments = [];
            state.loadingAppointments = false;
        },
        changeAppointmentFilters:(state, action)=>{
            state.filters= action.payload
        }
    },
})
export const {gettingAppointments, appointmentsResponse, appointmentsClear, changeAppointmentFilters} = AppointmentsSlice.actions;
export default AppointmentsSlice.reducer;