import { Tag } from "antd"
const CitiesTag=({ID, lookup, color,index})=>{
    return (
        <Tag color={color || "#a9a9a9"} key={index}>
            {
                lookup?.find(fin=>fin?.value===ID)?.label
            }
        </Tag>
    )
}
export {CitiesTag}