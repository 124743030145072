import {Row, Col} from "antd"
import { CitiesDateTimePicker, CitiesInput} from "../"
import moment from "moment"
import { addDays } from "../../shared"

const FUESection=({form, isFueAppointment, PRPSessions, setPRPSessions})=>{
    const handleChange = (key, value) => {
        if (key === "advanceFUEAmount") {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            advanceFUEAmount: value,
            balanceFUEAmount:
              parseFloat(form.getFieldValue("totalFUECost")) - value,
          });
        } else if (key === "totalFUECost") {
          let balanceAmount =
            value - parseFloat(form.getFieldValue("advanceFUEAmount"));
    
          form.setFieldsValue({
            ...form.getFieldsValue(),
            totalFUECost: value,
            balanceFUEAmount: isNaN(balanceAmount)
              ? form.getFieldValue("balanceFUEAmount")
              : balanceAmount,
          });
        } else if (key === "balanceFUEAmount") {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            advanceFUEAmount:
              parseFloat(form.getFieldValue("totalFUECost")) - value,
            balanceFUEAmount: value,
          });
        } 
    }
    return (
        <div>
                <Row gutter={16}>
                    <Col span={8}>
                        <CitiesDateTimePicker
                            datePicker
                            name="FUEAppointmentDate"
                            label="FUE Appointment Date"
                            required={isFueAppointment}
                            message= 'please select date'
                            value={form?.getFieldValue("FUEAppointmentDate")}
                            disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return current && current < moment(customDate, "YYYY-MM-DD");
                            }}
                            onChange={(value)=>{
                                if(!PRPSessions?.length)
                                setPRPSessions([
                                    { date: moment(addDays(value,4)), time: moment(addDays(value,4)+'T14:00'), cost: 0, status: 1},
                                    { date: moment(addDays(value,14)), time: moment(addDays(value,14)+'T14:00'), cost: 0, status: 1},
                                    { date: moment(addDays(value,48)), time: moment(addDays(value,48)+'T14:00'), cost: 0, status: 1},
                                ])
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesDateTimePicker
                            required={isFueAppointment}
                            message= 'please select time'
                            name="FUEAppointmentTime"
                            label="FUE Appointment Time"
                            value={form?.getFieldValue("FUEAppointmentTime")}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesInput
                            addonBefore="Rs"
                            name="totalFUECost"
                            label="Total FUE Cost"
                            required
                            message="Please enter total cost"
                            placeholder="e.g 75000"
                            value={form?.getFieldValue("totalFUECost")}
                            onChange={(e) => {
                                handleChange("totalFUECost", e.target.value);
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesInput
                            addonBefore="Rs"
                            name="advanceFUEAmount"
                            label="Advance FUE Amount"
                            required
                            message="Please enter advance amount"
                            placeholder="e.g 10000"
                            value={form?.getFieldValue("advanceFUEAmount")}
                            onChange={(e) => {
                                handleChange("advanceFUEAmount", e.target.value);
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesInput
                            addonBefore="Rs"
                            name="balanceFUEAmount"
                            label="Balance FUE Amount"
                            required
                            message="Please enter balance amount"
                            placeholder="e.g 65000"
                            value={form?.getFieldValue("balanceFUEAmount")}
                            onChange={(e) => {
                                handleChange("balanceFUEAmount", e.target.value);
                            }}
                        />
                    </Col>
                </Row>
        </div>
    )
}
export default FUESection