import { Space, Typography, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TableLoader } from '../../shared'
import { ActionButton } from '../'
import { EyeOutlined } from '@ant-design/icons'
import { ViewDraftDetail } from '../'
import { priorityLookup } from '../../shared'
import "./index.css"
const { Title } = Typography

const WhenToCallAgain = ({ title, data}) => {
  const { loading } = useSelector(state => state?.dashboard)
  const [state, setState] = useState([])
  const [visibleViewModal, setVisibleViewModal] = useState(false)
  const [viewData, setViewData] = useState(false)
  useEffect(() => {
    if (data?.length) {
      let temp = data?.map(row => (
        {
          ...row,
          clientPriority: {
            id: parseInt(row?.clientPriority),
            name: priorityLookup?.find(fin => fin?.id === parseInt(row?.clientPriority))?.name
          }
        }
      ))
      setState([...temp])
    }
  }, [data])
  const columns = [
    {
      title: 'Name',
      dataIndex: 'Client_info',
      render: col => col?.name
    },
    {
      title: 'Phone',
      dataIndex: 'Client_info',
      render: col => col?.telephone
    },
    {
      title: 'Priority',
      dataIndex: 'clientPriority',
      render: col => col?.name
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (data, row) => (
        <ActionButton
          title='view appointment'
          icon={<EyeOutlined />}
          onClick={() => { setVisibleViewModal(true); setViewData(row) }}
        />
      )
    },
  ]
  return (
    <Space
      direction="vertical"
      className="border-grey"
      style={{ width: '100%', borderRadius: '3px'}}
    >
      <Title level={5} className='remainders-bg px-3 py-2'>
        {
          title
        }
      </Title>
      <div className='px-3 py-2'>
        <Table
          columns={columns}
          dataSource={state}
          size= 'small'
          loading={
            {
              ...TableLoader,
              spinning: loading
            }
          }
          pagination={false}
          className='table-scroll'
        />
      </div>
      <ViewDraftDetail
        visible={visibleViewModal}
        data={viewData}
        onClose={() => { setVisibleViewModal(false); setViewData(null) }}
        isDashboardDraft
      />
    </Space>
  )
}
export { WhenToCallAgain }