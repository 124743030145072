import { Modal, Typography, Space, Divider, Button} from 'antd'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {actionsApi, LLFormat, recipentAreaLookup, treatmentTypeLookup, formatCurrency, priorityLookup2} from '../../shared'
import { HairLossStage } from '../../components'
import { CitiesTag } from '../Utils'
import { Addappointment } from '../../components'
import "./index.css"
const { Title, Text } = Typography

const ViewDraftDetail = ({ visible, data, isDashboardDraft, onClose }) => {
    const dispatch= useDispatch()
    const ref = React.createRef();
    const [state, setState] = useState(null)
    useEffect(() => {
        if (visible && data) {
            setState({...data})
        }
    }, [visible, data])
    const [visibleAppointmentModal, setVisibleAppointmentModal] = useState(false)
    const [row, setRow] = useState(null)
    const [editID, setEditID] = useState(null)
    const editDraft= ()=>{
        onClose()
        setRow({ ...data?.Client_info })
        setEditID(data?.id)
        dispatch(actionsApi?.setSteps({
            ...data,
            client_id: data?.Client_info?.id,
            stage: parseInt(data?.stage),
            clientPriority: parseInt(data?.clientPriority?.id)
        }))
        setVisibleAppointmentModal(true)
    }
    return (
        <div>
            <Modal
                title={
                    <Title level={5} className='my-0'>{state?.Client_info?.name}</Title>
                }
                centered
                width={700}
                visible={visible}
                onOk={onClose}
                onCancel={onClose}
                footer={
                    <Space>
                        <Button type='primary' onClick={editDraft}>Edit Draft</Button>
                        <Button type='primary' onClick={onClose}>Close</Button>
                    </Space>
                }
                >
                <div ref={ref} className='px-3'>
                    <div direction='vertical'>
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>Treatment:</Title>
                            <Space size={2}>
                                {
                                    state?.treatmentTypeIDs?.map((treatmentTypeID, index) =>
                                        <CitiesTag 
                                            ID={treatmentTypeID} 
                                            index={'treatment-type-'+index} 
                                            lookup={treatmentTypeLookup} 
                                            />
                                    )
                                }
                            </Space>
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>Recipent Area:</Title>
                            <Space size={2}>
                                {
                                    state?.recipentArea?.map((area, index) =>
                                        <CitiesTag 
                                            ID={area} 
                                            index={index} 
                                            lookup={recipentAreaLookup} 
                                            />
                                    )
                                }
                            </Space>
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>HEREDITARY BALDNESS (GENETIC):</Title>
                            <Text>
                                {
                                    state?.geneticDisease === 1 ? "Yes" : 'No'
                                }
                            </Text>
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>stage of hair loss:</Title>
                            <HairLossStage stage={state?.stage} />
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>Estimated Grafts:</Title>
                            <Text>
                                {
                                    state?.estimatedgrafts
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space className='detail-row'>
                            <Space>
                                <Title level={5} className='m-0'>Estimated FUE Cost:</Title>
                                <Text>
                                    {
                                        formatCurrency(state?.estimatedFUECost)
                                    }
                                </Text>
                            </Space>
                            <Space>
                                <Title level={5} className='m-0'>Estimated PRP Cost:</Title>
                                <Text>
                                    {
                                        formatCurrency(state?.estimatedPRPCost)
                                    }
                                </Text>
                            </Space>
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>In how many days he/she wants to have hair transplant surgery:</Title>
                            <Text>
                                {
                                    LLFormat(state?.estimatedDate)
                                }
                            </Text>
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>When to call again:</Title>
                            <Text>
                                {
                                    LLFormat(state?.callAgain)
                                }
                            </Text>
                        </Space>
                        <Divider />
                        <Space className='detail-row'>
                            <Title level={5} className='m-0'>Client Priority:</Title>
                            <CitiesTag 
                                ID={state?.clientPriority?.id} 
                                lookup={priorityLookup2} 
                                color={
                                    state?.clientPriority?.id === 1 ? '#4DC274' :
                                    state?.clientPriority?.id === 2 ? 'rgb(181, 181, 107)' :
                                    state?.clientPriority?.id === 3 ? '#F79400' :
                                    '#F70000'
                                  }
                                />
                        </Space>
                        <Divider />
                        <Space style={{ width: "100%" }} direction='vertical'>
                            <Title level={5} className='m-0'>Note:</Title>
                            <Text>
                                <span dangerouslySetInnerHTML={{ __html: state?.note }} />
                            </Text>
                        </Space>
                    </div>
                </div>
            </Modal>
            <Addappointment
                visible={visibleAppointmentModal}
                clientInfo={row}
                editID={editID}
                onClose={() => { setRow(null); setVisibleAppointmentModal(false); setEditID(null) }}
                isDraft
                isDashboardDraft= {isDashboardDraft}
            />
        </div>
    )
}
export {ViewDraftDetail}