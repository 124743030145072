import { useEffect, useState } from "react"
import { Typography, Skeleton,Space} from "antd"
import { useSelector, useDispatch } from "react-redux"
import { actionsApi } from "../../shared"
import { DashboardCard } from "./DashboardCard"
import { AppointmentsStatisticsData } from "../../Data/data"

const AppointmentsStatistics = () => {
    const dispatch= useDispatch()
    const { appointmentStatistics, loadingAppointmentStatistics } = useSelector(state => state?.dashboard)
    const [state, setState] = useState([])

    useEffect(()=>{
        dispatch(actionsApi?.getAppointmentStatistics())
    }, [])
    useEffect(() => {
        if (appointmentStatistics) {
           setState([
            { 
              ...AppointmentsStatisticsData[0], 
              count1: appointmentStatistics?.totalFueAppointments,
              count2: appointmentStatistics?.pendingFueAppointments,
              count3: appointmentStatistics?.completeFueAppointments,
              count4: appointmentStatistics?.cancelFueAppointments,
            },
            { 
              ...AppointmentsStatisticsData[1], 
              count1: appointmentStatistics?.totalPRPSessions,
              count2: appointmentStatistics?.pendingPRPSessions,
              count3: appointmentStatistics?.completePRPSessions,
              count4: appointmentStatistics?.cancelPRPSessions,
            }
          ])
        }
    }, [appointmentStatistics])
    return (
        <>
        {
            loadingAppointmentStatistics ?
            <Skeleton.Button 
                active 
                size='large' 
                shape='square'
                block
                style={{width:'100%', height:'329px'}}
                className='radius-3'
            />
            :
            <div className="border-box-0 height-328">
                <Typography.Title level={5} className='light-grey-bg px-3 py-2 my-0'>
                    Appointments Statistics
                </Typography.Title>
                <Space 
                    direction='vertical' 
                    className="width-100 scroll-bar" 
                    style={{height:'288px', overflowY: 'scroll'}}
                >
                    {
                         state?.map((data, index) =>
                            <div key={'appointments-statistics-card-' + index}>
                                <DashboardCard data={data}/>
                            </div>
                        )
                    }
                </Space>
            </div>
        }
        </>
    )
}
export {AppointmentsStatistics}
