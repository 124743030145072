import { Row, Col, Form, Button } from "antd"
import { CitiesInput, CitiesDateTimePicker, CitiesSelect } from "../"
const Step1 = ({ clientInfo, nextStep, style }) => {
    const sourceLookup = [
        { id: 1, name: 'Facebook' },
        { id: 2, name: 'Twitter' },
        { id: 3, name: 'Instagram' },
        { id: 4, name: 'Other' },
    ]
    return (
        <Form
            layout="vertical"
            style={style}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <CitiesInput
                        label="Name"
                        value={clientInfo?.name || ''}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        type='number'
                        label="Telephone #"
                        value={clientInfo?.telephone || ''}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesDateTimePicker
                        datePicker
                        label="Date of birth"
                        value={clientInfo?.dob || ''}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        type='number'
                        label="Age"
                        value={clientInfo?.age || ''}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        label="Residence"
                        value={clientInfo?.residence || ''}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        label="Occupation"
                        value={clientInfo?.occuption || ''}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesSelect
                        label="Source of information about our clinic"
                        value={clientInfo?.sourceID || ''}
                        options={sourceLookup}
                        disabled
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        label="Other"
                        value={clientInfo?.other_source || ''}
                        disabled
                    />
                </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='primary' onClick={nextStep}>Add Appointment</Button>
            </div>
        </Form>
    )
}
export default Step1