import { Typography, Space} from 'antd'
import './index.css'
const { Title, Text } = Typography

const HairForm = () => {
   
  return (
    <div className='justify-center w-100'>
        <div className='After-13-days-instructions-page'>
            <header></header>
            <div>
                <Space className='w-100' direction='vertical' size={8}>
                    <Space direction='vertical'>
                        <Space direction='vertical'>
                            <Title level={5} className='w-100 text-center' style={{display:'flex', justifyContent:'center'}}>
                                ہیئر ٹرانسپلانٹ کے 13 دن کے بعد کی ہدایات   
                            </Title>
                            <p>
                                <span>1- </span>
                                <span>
                                    ہیئر ٹرانسپلانٹ کے بعد پہلے دس دن کے لئے جو ہدایات آپ کو دی گئی تھیں وہ اب ختم ہوگئی ہیں، اب سے اگلے ایک ماہ کے لئے درج ذیل ہدایات پر عمل کریں۔  
                                </span>
                            </p>
                            <p>
                                <spam>2- </spam>
                                <span>
                                    آج سے آپ ٹرانسپلانٹ والی جگہ پر جلد کو صاف ہاتھ لگا سکتے ہیں، آپ کو سرکی جلد نسبتا کم حساس
                                </span>
                                <strong> (NUMB) </strong>
                                <span>
                                    محسوس ہوسکتی ہے ایسا بالکل نارمل ہے، یہ صورتحال رفتہ رفتہ اگلے 3 ماہ میں نارمل ہو جائے گی۔
                                </span>
                            </p>
                            <p>
                                <span>3- </span>
                                <span>
                                    13 دن بعد آپ 14 ویں دن ہیڈ واش کیلئے ضرور آئیں اگر نہ آسکیں تو آج رات سے آپ نے ٹرانسپلانٹ والی جگہ پر تیل لگا کر انگلیوں کی پوروں سے ایک دو منٹ مالش کرنا ہے ، اسطرح مالش کرنے سے جلد پر بنے کھرنڈ
                                </span>
                                <strong> (SCABS) </strong>
                                <span>
                                    آہستہ آہستہ اگلے ہفتہ دس دن میں گر جائیں گے، تیل رات بھر لگا رہنے دیں، اور اگلی صبح شیمپو سے دھو لیں۔  
                                </span>
                            </p>
                            <p>
                                <span>4- </span>
                                <span>
                                    بے بی شیمپو کا استعمال آئندہ دس دن تک جاری رکھیں اور دس دن کے بعد 
                                </span>
                                <strong> Hair Mex Shampoo </strong>
                                <span>
                                    یا کوئی نارمل ریگولر شیمپو روزانہ استعمال کریں۔ 
                                </span>
                            </p>
                            <p>
                                <span>5- </span>
                                <span>
                                نارمل طریقے سے شیمپو بالوں پر لگا کر ایک دومنٹ مالش کر کے سر شاور سے دھولیں ، نہانے کے بعد سر کو تولیے سے آہستہ آہستہ دبا کر خشک کر سکتے ہیں ہیں مگر تولیے سے رگڑنا نہیں ہے۔    
                                </span>
                            </p>
                            <p>
                                <span>6- </span>
                                <span>
                                    اگلے پندرہ دن میں سر کی جلد سے سارے کھرنڈ اتر جانے چاہیے، کھرنڈ کے ساتھ کافی سارے بال بھی ایک مرتبہ گر سکتے ہیں۔<br></br> بال دوبارہ تقریبا
                                </span>
                                <strong> 3 ماہ </strong>
                                <span>
                                    کے بعد آنا شروع کریں گے، اگر ٹرانسپلانٹ کے وقت آپ کے اپنے بال بھی سر میں موجود ہیں تو ان میں سے بھی کچھ بال عارضی طور پر گر جائیں گے، یہ بال بھی تقریباً 
                                </span>
                                <strong> 3 ماہ </strong>
                                <span>
                                    کے بعد واپس آنا شروع ہوں گے ، ہیئر ٹرانسپلانٹ کا رزلٹ اگلے  
                                </span>
                                <strong> 10, 9ماہ </strong>
                                <span>
                                    میں مکمل ہو جائے گا۔    
                                </span>
                            </p>
                            <p>
                                <span>7- </span>
                                <span>
                                    ہیئر برش کا استعمال فی الحال مت کریں، البتہ کنگھی استعمال کر سکتے ہیں کنگھی استعمال کرتے وقت خیال رہے کہ کنگھی جلد کے ساتھ زیادہ رگڑ کے استعمال نہ کی جائے۔ 
                                </span>
                            </p>
                            <p className='last-instruction'>
                                <Text strong>
                                    مندرجہ ذیل سے اگلے ایک ماہ کے لئے مکمل اجتناب کریں۔ 
                                </Text>
                                <span>
                                    ہیئر ڈائی، ہیئر ڈرائیر یا کوئی اور کیمیکل، سوئمنگ <br></br> بھاری ورزش، جا گنگ، ویٹ لفٹنگ وغیرہ منع ہے۔   
                                </span>
                            </p>
                        </Space>   

                    </Space>
                </Space>
            </div>
            <footer></footer>
        </div>
    </div>
  )
}

export {HairForm}