import React from "react"
import {Space, DatePicker, TimePicker, InputNumber } from "antd"
import moment from "moment"
import { DeleteOutlined } from "@ant-design/icons"
import { ActionButton } from "../actionButton"

const PRPModal = ({PRPSessions, handlePRPChange, onRemove }) => {
    console.log("session:", PRPSessions)
    return (
        <div>
            <Space direction="vertical" size={15} className="mb-3">
                {
                    PRPSessions?.map((session, index) =>
                        <Space key={'prp-session-' + index} size={10}>
                            <DatePicker
                                value={session?.date ? moment(session?.date, 'YYYY-MM-DD') : ''}
                                onChange= {value => handlePRPChange('date', value, index)}
                            />
                            <TimePicker
                                value={session?.time ? moment(session?.time) : ''}
                                format='HH:mm A'
                                onChange={value => handlePRPChange('time', value, index)}
                            />
                            <InputNumber
                                addonBefore="Rs"
                                // addonAfter="PKR"
                                type="number"
                                value= {session?.cost}
                                onChange={value => handlePRPChange('cost', parseInt(value || 0), index)}
                                style={{width:'150px'}}
                            />
                            <ActionButton
                                title='Remove session'
                                type='primary'
                                icon={<DeleteOutlined />}
                                danger
                                shape="circle"
                                onClick={() => onRemove(index)}
                            />
                        </Space>
                    )
                }
            </Space>
        </div>
    )
}
export default PRPModal