import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getAllClientImages = createAsyncThunk('clientImages',
    async (clientID, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingClientImages())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/viewclientimages/${clientID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    let tempArr = result?.clientImages?.map(data => {
                        return {
                            uid: data?.clientID,
                            url: data?.images,
                            cloudinaryID: data?.cludinaryID
                        }
                    })
                    dispatch(clientImagesResponse(tempArr))
                }
                else {
                    dispatch(clientImagesClear())
                    Alert("error", result?.message)
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(clientImagesClear())
                Alert("error", "Unable to load Client's Images")
            })
    }
)

const clientImagesSlice = createSlice({
    name: 'clientImages',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingClientImages: (state, action) => {
            state.loading = true;
        },
        clientImagesResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        clientImagesClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const { gettingClientImages, clientImagesResponse, clientImagesClear } = clientImagesSlice.actions
export default clientImagesSlice.reducer