import { useRef } from 'react'
import { Modal, Button} from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { Instructions } from './Instructions'
import { ReadyToPrint } from '../ReadyToPrint'

const PreOperationInstructions = ({ visible, onClose }) => {
    const print= useRef()
    const clickOnPrint= _ => print.current.click() 
    return (
        <div>
            <Modal
                title={'Pre-Operation Instructions'}
                centered
                width={'793.92px'}
                open={visible}
                onOk={onClose}
                onCancel={onClose}
                footer={
                    <Button
                        type="primary"
                        onClick={clickOnPrint}
                        icon={<PrinterOutlined/>}
                        className="btn-primary-grey"
                    >
                        Print Pre-Operation Instructions
                    </Button>
                }
            >
                <ReadyToPrint
                    name= 'Pre-Operation Instructions'
                    print= {print}
                    Component={<Instructions/>}
                />
            </Modal>
        </div>
    )
}
export {PreOperationInstructions}