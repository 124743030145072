import React, { useState, useEffect } from 'react'
import { Typography, Space, Input } from 'antd'
import './index.css'

const { Text } = Typography
const HairForm = ({state, handleChange}) => {
    
  return (
    <div className='justify-center'>
        <div className='history-page'>
            <header></header>
            <div>
                <Space className='w-100' direction='vertical' size={8}>
                    <Space size={1} direction='vertical' className='width-100'>
                        <Text className='m-0 fw-bold'>Hair fall history:</Text>
                        <div className='space-d'>
                            <Text>
                                Age when hair fall started
                            </Text>
                            <Input 
                                size='small'
                                value={state?.hairFallStartingAge}
                                onChange={(e)=> handleChange('hairFallStartingAge', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Family history of hair fall Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isFamilyDisease}
                                onChange={(e)=> handleChange('isFamilyDisease', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Any previous treatments for hair fall
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anyPreviousTreatments}
                                onChange={(e)=> handleChange('anyPreviousTreatments', e.target.value)}
                                />
                        </div>
                    </Space>
                    <Space size={1} direction='vertical' className='width-100'>
                        <Text className='m-0 fw-bold'>Medical History:</Text>
                        <div className='space-d'>
                            <Text>
                                General Health
                            </Text>
                            <Input 
                                size='small'
                                value={state?.generalHealth}
                                onChange={(e)=> handleChange('generalHealth', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Diabetes Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isDiabetes}
                                onChange={(e)=> handleChange('isDiabetes', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Asthma Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isAsthma}
                                onChange={(e)=> handleChange('isAsthma', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                IHD Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isIHD}
                                onChange={(e)=> handleChange('isIHD', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Hypertension Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isHypertension}
                                onChange={(e)=> handleChange('isHypertension', e.target.value)}
                                />
                        </div>
                        {/* <div className='space-d'>
                            <Text>
                                Hepatitis Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isHepatitis}
                                onChange={(e)=> handleChange('isHepatitis', e.target.value)}
                                />
                        </div> */}
                        <div className='space-d'>
                            <Text>
                                Jaundice Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isJaundice}
                                onChange={(e)=> handleChange('isJaundice', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Epilepsy Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isEpilepsy}
                                onChange={(e)=> handleChange('isEpilepsy', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Skin/Scalp Conditions Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.isSkinConditions}
                                onChange={(e)=> handleChange('isSkinConditions', e.target.value)}
                                />
                        </div>
                    </Space>
                    <Space size={1} direction='vertical' className='width-100'>
                        <div className='space-d'>
                            <Text>
                                Previous History of Local Anesthesia
                            </Text>
                            <Input 
                                size='small'
                                value={state?.localAnesthesiaHistory}
                                onChange={(e)=> handleChange('localAnesthesiaHistory', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Current Infection Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anyCurrentInfection}
                                onChange={(e)=> handleChange('anyCurrentInfection', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Others
                            </Text>
                            <Input 
                                size='small'
                                value={state?.other}
                                onChange={(e)=> handleChange('others', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Hospital admissions
                            </Text>
                            <Input 
                                size='small'
                                value={state?.hospitalAdmissions}
                                onChange={(e)=> handleChange('hospitalAdmissions', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Previous Surgeries
                            </Text>
                            <Input 
                                size='small'
                                value={state?.previousSurgeries}
                                onChange={(e)=> handleChange('previousSurgeries', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Trauma
                            </Text>
                            <Input 
                                size='small'
                                value={state?.trauma}
                                onChange={(e)=> handleChange('trauma', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Antibiotic course/TB course/Anti-viral
                            </Text>
                            <Input 
                                size='small'
                                value={state?.antibioticCourse}
                                onChange={(e)=> handleChange('antibioticCourse', e.target.value)}
                                />
                        </div>
                    </Space>
                    <Space size={1} direction='vertical' className='width-100'>
                        <Text className='m-0 fw-bold'>Examination:</Text>
                        <div className='space-d'>
                            <Text>
                                Pulse
                            </Text>
                            <Input 
                                size='small'
                                value={state?.pulse}
                                onChange={(e)=> handleChange('pulse', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                B.P
                            </Text>
                            <Input 
                                size='small'
                                value={state?.BP}
                                onChange={(e)=> handleChange('BP', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                BSR
                            </Text>
                            <Input 
                                size='small'
                                value={state?.BSR}
                                onChange={(e)=> handleChange('BSR', e.target.value)}
                                />
                        </div>
                    </Space>
                    <Space size={1} direction='vertical' className='width-100'>
                        <Text className='m-0 fw-bold'>Labs:</Text>
                        <div className='space-d'>
                            <Text>
                                Pre.Ops. Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anyPreOps}
                                onChange={(e)=> handleChange('anyPreOps', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                HIV
                            </Text>
                            <Input 
                                size='small'
                                value={state?.HIV}
                                onChange={(e)=> handleChange('HIV', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Hepatitis B/C
                            </Text>
                            <Input 
                                size='small'
                                value={state?.hepatitis}
                                onChange={(e)=> handleChange('hepatitis', e.target.value)}
                                />
                        </div>
                    </Space>
                    <Space size={1} direction='vertical' className='width-100'>
                        <Text className='m-0 fw-bold'>Medications:</Text>
                        <div className='space-d'>
                            <Text>
                                Aspirin/NSAIDS Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anyAspirin}
                                onChange={(e)=> handleChange('anyAspirin', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Steroids Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anySteroids}
                                onChange={(e)=> handleChange('anySteroids', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Anti-Coagulants Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anyAntiCoagulants}
                                onChange={(e)=> handleChange('anyAntiCoagulants', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Others Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.anyOthers}
                                onChange={(e)=> handleChange('anyOthers', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Allergies
                            </Text>
                            <Input 
                                size='small'
                                value={state?.allergies}
                                onChange={(e)=> handleChange('allergies', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Bleeding Tendencies
                            </Text>
                            <Input 
                                size='small'
                                value={state?.bleedingTendencies}
                                onChange={(e)=> handleChange('bleedingTendencies', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Cigarette Smoking Y/N
                            </Text>
                            <Input 
                                size='small'
                                value={state?.smoking}
                                onChange={(e)=> handleChange('smoking', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text>
                                Any other substance uses
                            </Text>
                            <Input 
                                size='small'
                                value={state?.otherSubstanceUses}
                                onChange={(e)=> handleChange('otherSubstanceUses', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text strong>
                                Recent travel history
                            </Text>
                            <Input 
                                size='small'
                                value={state?.recentTravelHistory}
                                onChange={(e)=> handleChange('recentTravelHistory', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text strong>
                                Flight date (if intention to travel):
                            </Text>
                            <Input 
                                size='small'
                                value={state?.upComingTravel}
                                onChange={(e)=> handleChange('upComingTravel', e.target.value)}
                                />
                        </div>
                        <div className='space-d'>
                            <Text strong>
                                Any Note:
                            </Text>
                            <Input 
                                size='small'
                                value={state?.note}
                                onChange={(e)=> handleChange('note', e.target.value)}
                                />
                        </div>
                    </Space>
                </Space>
            </div>
            {/* <footer></footer> */}
        </div>
    </div>
  )
}

export {HairForm}