import { Typography, Space } from "antd"
import "./index.css"
const { Title, Text} = Typography

const Instructions = () => {
    return (
        <div className="justify-center">
            <Space direction='vertical' className="pre-operation-instructions-page width-100">
                <div className="text-center heading my-0">
                    PRE-OPERATION INSTRUCTIONS
                </div>
                <Title level={5} className="text-center my-0">
                    FUE Hair Transplant
                </Title>
                <ul>
                    <li>
                        One day before of procedure, do not take Nicotine, Caffeine and Alcohol i.e. (No Smoking, Alcohol, Tea, Coffee, Chocolates and Fizzy Drinks).
                    </li>
                    <li>
                        10 Days before the procedure, do not take <Text strong>Aspirin</Text> and <Text strong>Disprin</Text> or their products. You can take Panadol, Paracetamol in case of headache or any other ache.
                    </li>
                    <li>
                        24 hours before procedure, do not go for hard exercise including sex.
                    </li>
                    <li>
                        5 days before, do not take any type of <Text strong>Multivitamin</Text> including Vitamin E.
                    </li>
                    <li>
                        On procedure day, come with Breakfast.
                    </li>
                    <li>
                        If you are using any medicine in routine, bring that along with you on procedure day.
                    </li>
                    <li>
                        Wear button Down/Front open Shirt on procedure day.
                    </li>
                    <li>
                        On procedure day, shampoo your head with "<Text strong>Organic hair scalp shampoo</Text>". After shampoo, do not apply any Gel or Oil on your scalp.
                    </li>
                    <li>
                        If you are <Text strong>allergic to any medicine</Text>, please inform us in advance.
                    </li>
                    <li>
                        Bring Blood tests i.e. <Text strong>PT, APTT, HIV, HEP.'B'</Text> and <Text strong>HEP.'C', Blood CP, BSR</Text> along with you on procedure day.
                    </li>
                </ul>
                <Space direction="vertical" size={1}>
                    <Text strong>
                        Cap CEFSPAN 400mg (daily one) x5 days
                    </Text>
                    <Text strong>
                        (1 capsule after breakfast on the day of surgery)
                    </Text>
                </Space>
            </Space>
        </div>
    )
}
export { Instructions }