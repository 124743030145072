import { Typography, Space} from 'antd'
import './index.css'
import { LLFormat, addDays, convertTo12HourFormat} from '../../../shared'
import { useEffect, useState } from 'react'

const { Text } = Typography
const HairForm = ({prpSessions, fueAppointmentDate}) => {
    console.log(prpSessions?.prpSessions)
  const [state, setState]= useState([])
  const [totalPrpCost, setTotalPrpCost]= useState(0)
  useEffect(()=>{
    if(prpSessions?.length)
        setState(prpSessions)
    else
        setState([])
  }, [prpSessions])
  useEffect(()=>{
    if(state?.length)
    {
        let total=0
        for(let i=0;i<state?.length;i++)
            total+= parseInt(state[i]?.cost)
        setTotalPrpCost(total)
    }
    else 
        setTotalPrpCost(0)
  }, [state])
  return (
    <div className='justify-center w-100'>
        <div className='post-operation-instructions-page'>
            <header></header>
            <div>
                <Space className='w-100' direction='vertical' size={8}>
                    <Space direction='vertical'>
                        <Space direction='vertical'>
                            <Text className='fw-bold width-100'>آپریشن کے بعد کی ہدایات</Text>
                            <Text className='fw-bold-13 width-100 my-0'>
                                اگلے 48 گھنٹوں کے لئے
                            </Text>
                            <p>
                                <span>1- </span>
                                <span>
                                    سر کو نیچے کی طرف بالکل نہیں جھکانا بلکہ اسے اونچا سیدھا رکھنا ہے، رکوع اور سجدے اشارے سے کریں۔ آپ نے جوتا پہنا ہو یا نیچے پڑی ہوئی چیز کو اٹھانا ہوتو سرکوسیدھارکھنا ہے۔   
                                </span>
                            </p>
                            <p>
                                <spam>2- </spam>
                                <span>
                                    بالکل سیدھا لیٹنا ہے کروٹ نہیں لینی ۔       
                                </span>
                            </p>
                            <p>
                                <span>3- </span>
                                <span>
                                    جب آپ بستر پر سونے کیلئے جائیں تو اپنے سر کے نیچے دو یا تین عدد تکیے رکھیں اور کروٹ لے کر نہیں سونا۔  
                                </span>
                            </p>
                            <p>
                                <span>4- </span>
                                <span>
                                    سگریٹ، نسوار، چائے، کافی، اسپرین ، شراب اور کولڈرنک ( پیپسی ، سیون اپ وغیرہ ) مکمل طور پر منع ہے۔
                                </span>
                            </p>
                            <p>
                                <span>5- </span>
                                <span>
                                    کوئی بھی وزنی چیز نہیں اٹھانی ۔
                                </span>
                            </p>
                            <p>
                                <span>6- </span>
                                <span>
                                    سر کو پانی ، گرد اور آلودگی سے محفوظ رکھیں ۔
                                </span>
                            </p>
                            <p>
                                <span>7- </span>
                                <span>
                                    پٹی کو چوتھے دن 
                                </span>
                                <input type='date' defaultValue={addDays(fueAppointmentDate,4)}/>
                                <span>کو</span>
                                <input type='time' defaultValue={'14:00'}/>
                                <span>
                                    بجے آکر اتروائیں یا پٹی کو انگلیوں کی مدد سے آگے کی طرف کھینچیں اور درمیان سے قینچی سے کاٹ لیں اور پیچھے کی طرف اتار لیں۔
                                </span>
                            </p>
                            <p>
                                <span>8- </span>
                                <span>
                                    پی آر پی سیشن 
                                </span>
                                {
                                    state?.map((session, index)=>
                                        <span>
                                            <span className='fw-600 px-1'>
                                                {
                                                    ' '+LLFormat(session?.date)+' - '+ convertTo12HourFormat(session?.time)
                                                }
                                            </span>
                                            {
                                                index===0 ? ' کواور ' : index<(state?.length-1) ? ' اور' : ''
                                            }
                                        </span>
                                    )
                                }
                                <span>
                                    کو ہونا چاہیے۔
                                </span>
                                <span>
                                    پی آر پی کے علیحدہ سے   
                                </span>
                                <span className='fw-bold px-1'>
                                    {totalPrpCost}
                                </span>
                                <span>
                                    روپے چار جز ہونگے۔
                                </span>
                            </p>
                        </Space>       
                        <Space direction='vertical'>
                            <Text className='fw-bold-13 width-100'>
                                سر دھونے کا طریقہ
                            </Text>
                            <p className='head-wash-instruction'>
                                <span>
                                    ایک مگ میں سادہ نیم گرم پانی  ملائیں اس میں بے بی یا آرگینک شیمپو ملائیں شیمپو کی مقدار قدرِ کم ہو جس سے جھاگ آسانی سے بن جائے پھر جھاگ والا پانی پورے سر پر ڈالیں( نئے ٹرانسپلانٹ کیے ہوئے بالوں پر بھی اور سر کے پچھلے حصے پر بھی) جہاں سے بال نکلتے ہیں (Donor Area) وہاں پر انگلیوں کی مدد سے اچھی طرح رگڑ لیں۔
                                </span>
                                <strong>
                                     یاد رہے نئے بالوں کو نہ ہی ہاتھ لگانا ہے نہ ہی رگڑنا ہے۔ 
                                </strong>
                                <span>
                                     پھر سر پر زیادہ سے زیادہ صاف پانی ڈالیں تاکہ ساری جھاگ نکل جائے۔ خشک کرنے کے لیے تولیے کا استعمال نہ کریں سر کو قدرتی طور پر خشک ہونے دیں۔ پھر سر کے پچھلے حصے ( Donor Area) پر polyfax cream لگا سکتے ہیں ۔
                                </span>
                            </p>
                        </Space>
                        <Space direction='vertical'>
                            <Text className='fw-bold-13 width-100'>10 دن تک</Text>
                            <p>
                                <span>1- </span>
                                <span>
                                    سر پر شاور کا استعمال نہیں کرنا ، باقی جسم پر کر سکتے ہیں۔
                                </span>
                            </p>
                            <p>
                                <span>2- </span>
                                <span>
                                    سر کو تو لیہ سے خشک نہیں کرنا قدرتی طور پر خشک ہونے دیں۔    
                                </span>
                            </p>
                            <p>
                                <span>3- </span>
                                <span>
                                    سر کو چھونا نہیں ، ٹوپی نہیں پہنی اور کپڑے بدلتے ہوئے احتیاط کریں کہ نئے بالوں کو رگڑ نہ لگے۔
                                </span>
                            </p>
                            <p>
                                <span>4- </span>
                                <span>
                                    کسی قسم کی ورزش نہیں کرنی۔
                                </span>
                            </p>
                            <p>
                                <span>5- </span>
                                <span>
                                    جس جگہ پر بال لگائے ہیں وہاں خارش محسوس کریں تو پٹی سے اس جگہ پر ہلکا سا دباؤ ڈالیں ، اس جگہ کو رگڑنا نہیں ہے۔  
                                </span>
                            </p>
                            <p>
                                <span>6- </span>
                                <span>
                                    14دن بعد 
                                </span>
                                <input type='date' defaultValue={addDays(fueAppointmentDate,14)}/>
                                <span>کو</span>
                                <input type='time' defaultValue={'14:00'}/>
                                <span>
                                    بجے چیک اپ کے لئے آنا ہے۔   
                                </span>
                            </p>
                            <p>
                                <span>7- </span>
                                <span>
                                    کسی بھی حادثہ کی صورت میں اگر کہیں سے قلم(Graft) نکل جائے تو وہاں Bleeding ہو سکتی ہے۔ ایسی صورت میں فوراً وہاں پٹی رکھ کر 5 منٹ کیلئے ہلکا سا پریشر دیں اور پھر پورے 5 منٹ بعد پٹی کو سیدھا اوپر اٹھا لیں ہر قسم کی Bledding صرف 5 منٹ پر یشر دینے سے رک جاتی ہے۔
                                </span>
                            </p>
                            <p>
                                <span>8- </span>
                                <span>
                                    تیراں دن کے بعد کی ہدایات کا پرنٹ چودہویں دن لینا نہ بھولیں۔
                                </span>
                            </p>
                            <p className='last-instruction'>
                                <span>
                                    کسی بھی ایمر جنسی کی صورت میں مندرجہ ذیل نمبرز پر رابطہ کریں۔ 
                                </span>
                                <span>
                                    Tel: 051-4906867, Cell: 0300-5029677
                                </span>
                            </p>
                        </Space>
                    </Space>
                </Space>
            </div>
            <footer></footer>
        </div>
    </div>
  )
}

export {HairForm}