import { createSlice } from '@reduxjs/toolkit'

const OuthFormSlice = createSlice({
    name: 'outhForm',
    initialState: {
        step1Data: {},
        step2Data: {},
    },
    reducers: {
        setStep1Data: (state, action) => {
            state.step1Data = action.payload
        },
        setStep2Data: (state, action) => {
            state.step2Data = action.payload
        }
    },
})
export const { setStep1Data, setStep2Data } = OuthFormSlice.actions;
export default OuthFormSlice.reducer;