import { useState} from "react"
import {Row, Col, Space, Button, Typography} from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { CitiesDateTimePicker, CitiesInput} from "../"
import PRPModal from "./PRPModal"
import moment from "moment"
const {Text}= Typography

const PRPSection=({form, PRPSessions, setPRPSessions})=>{
    
    const handleChange = (key, value) => { 
        if (key === "advancePRPAmount") {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            advancePRPAmount: value,
            balancePRPAmount: form.getFieldValue("totalPRPCost") - value,
          });
        }
        else if (key === "balancePRPAmount") {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            balancePRPAmount: value,
            advancePRPAmount: form.getFieldValue("totalPRPCost") - value,
          })
        }
      }
    const handlePRPChange = (key, value, index) => {
        if (key === "add") {
            setPRPSessions([
            ...PRPSessions,
            { date: moment(), time: moment(), cost: 0, status: 1},
            ])
            return
        }
        PRPSessions[index] = {
          ...PRPSessions[index],
          [key]: value,
        }
        if(key === 'cost')
            {
                for(let i=0; i< PRPSessions?.length; i++)
                    {
                        if(i===index)
                            continue
                        value+= PRPSessions[i]?.cost
                    }
                form.setFieldsValue({
                    ...form.getFieldsValue(),
                    totalPRPCost: value,
                    balancePRPAmount: value,
                    advancePRPAmount:0
                })
            }
        setPRPSessions([...PRPSessions]);
    }
    const handleDeletePRPSession = (index) => {
        form.setFieldsValue({
            ...form.getFieldsValue(),
            totalPRPCost: form.getFieldValue('totalPRPCost') - PRPSessions[index]?.cost
        })
        const data = PRPSessions?.filter((_, i)=> i!==index)
        setPRPSessions([...data])
        let value=0
        for(let i=0; i< data?.length; i++)
            value+= data[i]?.cost
        form.setFieldsValue({
            ...form.getFieldsValue(),
            totalPRPCost: value,
            balancePRPAmount: value,
            advancePRPAmount:0
        })
    }


    return (
        <div>
            <Row gutter={16}>
                <Col span={24}>
                    <Space className="py-3">
                        <Text strong>Add PRP session?</Text>
                        <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<PlusOutlined />}
                            style={{ transform: "translateY(-3px)" }}
                            onClick={() => handlePRPChange('add')}
                        />
                    </Space>
                </Col>
                <Col span={24}>
                    <PRPModal
                        PRPSessions={PRPSessions}
                        handlePRPChange={handlePRPChange}
                        onRemove={handleDeletePRPSession}
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        addonBefore="Rs"
                        disabled
                        name="totalPRPCost"
                        label="Total PRP Cost"
                        required
                        message="Please enter total cost"
                        placeholder="e.g 7000"
                        value={form?.getFieldValue("totalPRPCost")}
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        addonBefore="Rs"
                        name="advancePRPAmount"
                        label="Advance PRP Amount"
                        required
                        message="Please enter advance amount"
                        placeholder="e.g 2000"
                        value={form?.getFieldValue("advancePRPAmount")}
                        onChange={(e) => {
                            handleChange("advancePRPAmount", e.target.value);
                        }}
                    />
                </Col>
                <Col span={8}>
                    <CitiesInput
                        addonBefore="Rs"
                        name="balancePRPAmount"
                        label="Balance PRP Amount"
                        required
                        message="Please enter balance amount"
                        placeholder="e.g 5000"
                        value={form?.getFieldValue("balancePRPAmount")}
                        onChange={(e) => {
                            handleChange("balancePRPAmount", e.target.value);
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}
export default PRPSection