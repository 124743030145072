import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getAllClientsEndResult = createAsyncThunk('clientsEndResult',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingClientsEndResult())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/ViewAllClientEndResult`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                {
                    let tempArr=[]
                    if(result?.clientEndResults)
                    {
                        tempArr=[
                            ...result?.clientEndResults?.map(data=>(
                                {
                                    ...data,
                                    file:JSON.parse(data?.file),
                                }
                            ))
                        ]
                    }
                    dispatch(clientsEndResultResponse(tempArr))
                }
                else
                {
                    dispatch(clientsEndResultClear())
                    Alert("error",result?.message) 
                }
            })
            .catch(error => {
                alert(error)
                dispatch(clientsEndResultClear())
                Alert("error","error while loading Clients End Results") 
            })
    }
)

const ClientsEndResultSlice = createSlice({
    name: 'ClientsEndResult',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingClientsEndResult: (state, action) => {
            state.loading = true;
        },
        clientsEndResultResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        clientsEndResultClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingClientsEndResult, clientsEndResultResponse, clientsEndResultClear} = ClientsEndResultSlice.actions
export default ClientsEndResultSlice.reducer