import { Modal, Typography, Space, Divider, Button, } from 'antd';
import React, { useEffect, useState, useRef} from 'react'
import { useDispatch } from 'react-redux';
import {actionsApi, LLFormat, convertTo12HourFormat, formatCurrency, recipentAreaLookup, treatmentTypeLookup } from '../../shared';
import { HairLossStage } from '../../components'
import { CitiesTag } from '../Utils'
import { Addappointment } from '../../components'
import "./index.css"
import { ReadyToPrint } from '../Printer/ReadyToPrint';
import { PrinterOutlined } from '@ant-design/icons';
const { Title, Text } = Typography


const View= ({data})=>{
    const ref = React.createRef()
    const [state, setState]= useState(null)
    useEffect(() => {
        if (data) {
            setState({
                ...data
            })
        }
    }, [data])
    return (
        <div ref={ref} className='print-fue-appointment-detail'>
            <header></header>
            <div direction='vertical'>
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>Name:</Title>
                    <Text>{state?.Client_info?.name}</Text>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>Phone #</Title>
                    <Text>{state?.Client_info?.telephone}</Text>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>Treatment:</Title>
                    <Space>
                        {
                            state?.treatmentTypeIDs?.map((treatmentTypeID, index) =>
                                <CitiesTag 
                                    ID={treatmentTypeID} 
                                    index={'treatment-type-'+index} 
                                    lookup={treatmentTypeLookup} 
                                    />
                            )
                        }
                    </Space>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>Recipent Area:</Title>
                    <Space>
                        {
                            state?.recipentArea?.map((area, index) =>
                                <CitiesTag 
                                    ID={area} 
                                    index={'recipent-area-'+index} 
                                    lookup={recipentAreaLookup} 
                                    />
                            )
                        }
                    </Space>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>HEREDITARY BALDNESS (GENETIC):</Title>
                    <Text>
                        {
                            state?.geneticDisease === 1 ? "Yes" : 'No'
                        }
                    </Text>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>stage of hair loss:</Title>
                    <HairLossStage stage={state?.stage} />
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>Estimated Grafts:</Title>
                    <Text>
                        {
                            state?.estimatedgrafts
                        }
                    </Text>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>PRP Sessions:</Title>
                    <Space className='prp-sessions-bar scroll-bar'>
                        {
                            state?.PRPSessions?.map((session, index) =>
                                <>
                                    <span key={'session-' + index}>
                                        {
                                            LLFormat(session.date)+" "+convertTo12HourFormat(session?.time)
                                        }
                                    </span>
                                    {index < (state?.PRPSessions?.length - 1) && " - "}
                                </>
                            )
                        }
                    </Space>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Title level={5} className='m-0'>Appointment Date:</Title>
                    <Text>
                        {
                            state?.FUEAppointmentDate ? 
                            (LLFormat(state?.FUEAppointmentDate) +' '+ convertTo12HourFormat(state?.FUEAppointmentTime)) 
                            : ''
                        }
                    </Text>
                </Space>
                <Divider />
                <Space className='detail-row'>
                    <Space>
                        <Title level={5} className='m-0'>Total Amount:</Title>
                        <Text>
                            {
                                formatCurrency(state?.totalFUECost)
                            }
                        </Text>
                    </Space>
                    <Space>
                        <Title level={5} className='m-0'>Advance:</Title>
                        <Text>
                            {
                                formatCurrency(state?.advanceFUEAmount)
                            }
                        </Text>
                    </Space>
                    <Space>
                        <Title level={5} className='m-0'>Remaining:</Title>
                        <Text>
                            {
                                formatCurrency(state?.balanceFUEAmount)
                            }
                        </Text>
                    </Space>
                </Space>
                <Divider />
                <Space style={{ width: "100%" }} direction='vertical'>
                    <Title level={5} className='m-0'>Any Note:</Title>
                    <Text>
                        <span dangerouslySetInnerHTML={{ __html: state?.note }} />
                    </Text>
                </Space>
            </div>
        </div>
    )
}
const ViewFUEAppointment = ({ visible, data, isDashboardFUE, onClose }) => {
    const dispatch= useDispatch()
    const print= useRef()
    const clickOnPrint= _ => print.current.click() 
    
    const [visibleAppointmentModal, setVisibleAppointmentModal] = useState(false)
    const [row, setRow] = useState(null)
    const [editID, setEditID] = useState(null)
    const editAppointment= ()=>{
        onClose()
        setRow({ ...data?.Client_info })
        setEditID(data?.id)
        dispatch(actionsApi?.setSteps({
            ...data,
            client_id: data?.Client_info?.id,
            stage: parseInt(data?.stage),
            clientPriority: parseInt(data?.clientPriority?.id)
        }))
        setVisibleAppointmentModal(true)
    }
    return (
        <div>
            <Modal
                title={
                    <Title level={5} className='my-0'>{data?.Client_info?.name}'s FUE Appointment Detail</Title>
                }
                centered
                width={800}
                visible={visible}
                onOk={onClose}
                onCancel={onClose}
                footer={
                    <Space>
                        <Button danger onClick={onClose}>Close</Button>
                        <Button 
                            type='primary' 
                            onClick={clickOnPrint}
                            icon={<PrinterOutlined/>}
                        >
                            Print FUE Details
                        </Button>
                        <Button 
                            type='primary' 
                            onClick={editAppointment}
                            className='fue-btn'
                        >
                            Edit FUE
                        </Button>
                    </Space>
                }
                >
                <ReadyToPrint
                    name= {data?.Client_info?.name+"'s Fue Appointment detail"}
                    print= {print}
                    Component={<View data={data}/>}
                />
            </Modal>
            <Addappointment
                visible={visibleAppointmentModal}
                clientInfo={row}
                editID={editID}
                onClose={() => { setRow(null); setVisibleAppointmentModal(false); setEditID(null) }}
                isFUE
                isDashboardFUE= {isDashboardFUE}
            />
        </div>
    )
} 
export {ViewFUEAppointment}