import {Image, Typography, Space} from "antd"
const {Title}= Typography
export const DashboardCard= ({data})=>{
    return (
        <div 
            direction="vertical" 
            className='dashboard-card space-between-center px-3 py-2 border-grey'
            >
            <Image 
                src={'/assets/images/'+data?.icon}
                alt='hair-plus-international'
                width={ data?.title4 ? '90px' : '55px'}
                preview={false}
                />
            <Space direction="vertical" className="flex-col-justify-end">
                {
                    data?.title1 ?
                    <>
                        <Title level={5} className="my-0 brand-color">
                            {data?.title1+": "+data?.count1}
                        </Title>
                        <Title level={5} className="my-0">
                            {data?.title2+": "+data?.count2}
                        </Title>
                    </>
                    :
                    <>
                        <Title level={5} className="my-0">{data?.count || 0}</Title>
                        <Title level={5} className="my-0">
                            {data?.title}
                        </Title>
                    </>
                }
                {
                    data?.title4 ?
                    <>
                        <Title level={5} className="my-0">
                            {data?.title3+": "+data?.count3}
                        </Title>
                        <Title level={5} className="my-0">
                            {data?.title4+": "+data?.count4}
                        </Title>
                    </>
                    :
                    <>
                    </>
                }
            </Space>
        </div>
    )
}