import { message } from "antd"
import { domainUrl } from "../constants/apiconstans/domanUrl"

const updatePRPSession=(data)=>{
    const tokenString = localStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`)
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(`${domainUrl}/updatePRPsession`, requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.success)
                {
                    message.success("Session updated successfully.")
                    return 1
                }
            else       
                throw result?.error || result?.message
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
let PRPSession={updatePRPSession}
export {PRPSession}