import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../components';
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { currentMonthStartEnd } from '../../../helpers';

export const loadClients = createAsyncThunk('Clients',
    async (obj, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingClients())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNo", obj?.pageNo)
        myHeaders.append("recordsPerPage", obj?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({...obj?.data})
        };
        fetch(domainUrl + `/viewAllClients`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                {
                    dispatch(clientsResponse(result?.clients))
                    dispatch(setPages({pageNo: obj?.pageNo, pageSize: obj?.pageSize, totalRecords: result?.totalRecords}))
                }
                else
                {
                    dispatch(clientsClear())
                    throw  'error'
                }
            })
            .catch(error => {dispatch(clientsClear()); Alert("error","error while loading clients")})
    }
)
const ClientsSlice = createSlice({
    name: 'clients',
    initialState: {
        clients: [],
        loadingClients: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters:{
            name:'',
            age:'',
            fromToDate: currentMonthStartEnd()
        }
    },
    reducers: {
        gettingClients: (state, action) => {
            state.loadingClients = true;
        },
        clientsResponse: (state, action) => {
            state.clients = action.payload;
            state.loadingClients = false;
        },
        clientsClear: (state, action) => {
            state.clients = [];
            state.loadingClients = false;
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        changeClientFilters:(state, action)=>{
            state.filters= action.payload
        }
    },
})
export const {gettingClients, clientsResponse, clientsClear, setPages, changeClientFilters} = ClientsSlice.actions;
export default ClientsSlice.reducer;