import { Alert } from "../../../components"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
const addUpdateYoutubeVideo=(data)=>{
    const tokenString = localStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl+(data.get("id")?'/updateVideo':'/createYoutubeVideo'),requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.success)
                {
                Alert("success",result?.message)
                return 1
                }
            else       
                throw result?.message
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const indexYoutubeVideos=(data)=>{
    const tokenString = localStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`)
    myHeaders.append("content-type", `application/json`);
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(domainUrl+'/updateVideoIndex',requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.success)
                 return 1
            else       
                throw 'error while indexing youtube videos'
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const removeYoutubeVideo=(ID)=>{
    const tokenString = localStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`)
    var requestOptions = {
      headers: myHeaders,
      method: 'DELETE',
      redirect: 'follow'
    }
    return (
      fetch(domainUrl+'/deleteYoutubeVideo/'+ID,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.success)
                {
                    Alert("success",result?.message)
                    return 1
                }
            else       
                throw result?.message
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
export {addUpdateYoutubeVideo, removeYoutubeVideo, indexYoutubeVideos}