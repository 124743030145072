import { Space, Typography, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi, convertTo12HourFormat, TableLoader } from '../../shared'
import { ActionButton, ViewFUEAppointment} from '../'
import { EyeOutlined } from '@ant-design/icons'
import "./index.css"
const { Title } = Typography

const TomorrowAppointments = () => {
  const dispatch = useDispatch()
  const { tomorrowAppointments, loadingTomorrowAppointments } = useSelector(state => state?.dashboardAppointments)
  const [state, setState] = useState([])
  const [visibleViewModal, setVisibleViewModal] = useState(false)
  const [viewData, setViewData] = useState(false)
  useEffect(() => {
    dispatch(actionsApi?.loadTomorrowAppointments())
  }, [])
  useEffect(() => {
    if (tomorrowAppointments) 
      setState([...tomorrowAppointments])
  }, [tomorrowAppointments])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Client_info',
      render: col => col?.name
    },
    {
      title: 'Phone',
      dataIndex: 'Client_info',
      render: col => col?.telephone 
    },
    {
      title: 'Time',
      dataIndex: 'FUEAppointmentTime',
      render: col => convertTo12HourFormat(col)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, row) => (
        <ActionButton
          title='view appointment'
          icon={<EyeOutlined />}
          onClick={() => { setVisibleViewModal(true); setViewData(row) }}
        />
      )
    },
  ]

  return (
    <Space
      direction="vertical"
      className="border-grey"
      style={{ width: '100%', borderRadius: '3px'}}
    >
      <Title level={5} className='fue-bg px-3 py-2'>
        Tomorrow FUE Appointments
      </Title>
      <div className='px-3 py-2'>
        <Table
          columns={columns}
          dataSource={state}
          loading={
            {
              ...TableLoader,
              spinning: loadingTomorrowAppointments
            }
          }
          pagination={false}
          size='small'
          className='table-scroll'
        />
      </div>
      <ViewFUEAppointment
        visible={visibleViewModal}
        data={viewData}
        onClose={() => { setVisibleViewModal(false); setViewData(null) }}
        isDashboardFUE
      />
    </Space>
  )
}
export { TomorrowAppointments }