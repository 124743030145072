import { configureStore } from "@reduxjs/toolkit";
import {
	loadSoicalMedias,
	users,
	loginCheck,
	login,
	categories,
	places,
	roles,
	loadClients,
	loadAllAppointments,
	googleReviews,
	featureLogo,
	clientsEndResult,
	clientImages,
	dashboard,
	appointmentDetails,
	dashboardAppointments,
	youtubeVideos,
	draft,
	allPRPAppointments,
	allFUEAppointments,
	//medicines
	medicines,
	// notifications
	notifications,
	outhForm,
	printReceiptData,
} from "./action"
const store = configureStore({
	reducer: {
		loadSoicalMedias,
		users,
		loginCheck,
		login,
		categories,
		places,
		roles,
		loadClients,
		loadAllAppointments,
		googleReviews,
		featureLogo,
		clientsEndResult,
		clientImages,
		dashboard,
		appointmentDetails,
		dashboardAppointments,
		youtubeVideos,
		draft,
		allPRPAppointments,
		allFUEAppointments,
		//medicines
		medicines,
		//notifications
		notifications,
		outhForm,
		printReceiptData
	},
})
export default store;