import { loadSoicalMedias, SoicalMediasResponse, SoicalMediasClear } from "./action/socialMedia"
import { gettingUsers, usersResponse, usersClear, getAllUsers } from "./action/users"

// import { AuthCheck } from "./action/login/loginCheck"
import { login, loginResponse, loginClear, AuthCheck } from "./action/login"
import { getCategories, categoriesResponse, categoriesClear } from "./action/categories"
import { getPlaces, placesResponse, placesClear } from "./action/places"
import { rolesResponse, rolesError, rolesClear, showAllrole } from "./action/roles"
import { clientsResponse, loadClients, changeClientFilters } from "./action/client"
import { appointmentsResponse, loadAllAppointments, changeAppointmentFilters } from "./action/appointments"
import { loadTodaysAppointments, loadTomorrowAppointments } from "./action/appointments/dashboardAppointments"

import { googleReviewsResponse, getAllGoogleReviews } from "./action/google reviews"
import { featureLogoResponse, getAllFeatureLogo } from "./action/feature logo"
import { clientsEndResultResponse, getAllClientsEndResult } from "./action/clients new result"
import { getAllClientImages, clientImagesResponse } from "./action/client images"
//dahboard
import {getStatistics, getAppointmentStatistics, loadDashboard, loadFUEAppointmentCalendar} from "./action/dashboard"
import { setSteps, setStep2, setStep3, clearSteps, loadAppointmentByID } from "./action/appointments/appointmentDetail"
import { loadAllYoutubeVideos, youtubeVideosResponse } from "./action/YoutubeVideos"
import { loadAllDraft, changeDraftFilters } from "./action/Draft"
//PRP Session
import { loadAllPRPAppointments, PRPappointmentsResponse, changePRPAppointmentFilters} from "./action/PRPAppointments"
import { loadAllFUEAppointments, changeFUEAppointmentFilters } from "./action/FUEAppointments"
//medicines
import { getAllMedicines, medicinesResponse } from "./action/Medicines"
//notifications
import {getUnseenNotificationsCount, unseenNoticationsCount, clearUnseenNoticationsCount, 
    showAllNotifications, clearNotifications, notificationsClear } from "./action/Notifications"

export const actionsApi = {
    loadSoicalMedias,
    SoicalMediasResponse,
    SoicalMediasClear,
    gettingUsers, usersResponse, usersClear, getAllUsers,
    AuthCheck,
    login,
    loginResponse,
    loginClear,
    getCategories,
    categoriesResponse,
    categoriesClear,
    getPlaces,
    placesResponse,
    placesClear,
    rolesResponse,
    rolesError,
    rolesClear,
    showAllrole,
    clientsResponse,
    loadClients,
    changeClientFilters,
    appointmentsResponse,
    loadAllAppointments,
    //PRP sessions
    loadAllPRPAppointments,
    PRPappointmentsResponse,
    changePRPAppointmentFilters,
    changeAppointmentFilters,
    loadTodaysAppointments,
    loadTomorrowAppointments,
    //
    googleReviewsResponse,
    getAllGoogleReviews,
    featureLogoResponse,
    getAllFeatureLogo,
    clientsEndResultResponse,
    getAllClientsEndResult,
    getAllClientImages,
    clientImagesResponse,
    //dashobard
    getStatistics,
    getAppointmentStatistics,
    loadDashboard,
    loadFUEAppointmentCalendar,
    setStep2,
    setStep3,
    clearSteps,
    loadAppointmentByID,
    setSteps,
    loadAllYoutubeVideos,
    youtubeVideosResponse,
    loadAllDraft,
    changeDraftFilters,
    loadAllFUEAppointments,
    changeFUEAppointmentFilters,
    //medicines
    getAllMedicines,
    medicinesResponse,
    //notifications
    getUnseenNotificationsCount,
    unseenNoticationsCount, 
    clearUnseenNoticationsCount,
    showAllNotifications, 
    clearNotifications, 
    notificationsClear
}
