import { message } from "antd"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import { checkAuthorization } from "../../helpers"

const addUpdateMedicine=(data)=>{
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    let endPoint= data?.id ? '/updateMedication' : '/createMedication'
    return (
      fetch(domainUrl+endPoint, requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.success)
                {
                    message.success(result?.message)
                    return 1
                }
            else       
                throw result?.error || result?.message
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const deleteMedicine=(recordID)=>{
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    var requestOptions = {
      headers: myHeaders,
      method: 'DELETE',
      redirect: 'follow'
    }
    return (
      fetch(`${domainUrl}/deleteMedication/${recordID}`, requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.success)
                {
                    message.success('Medication deleted successfully')
                    return 1
                }
            else       
                throw result?.message
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
let medications={addUpdateMedicine, deleteMedicine}
export {medications}