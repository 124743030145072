import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const showAllrole = createAsyncThunk('roles',
    async (data, { dispatch, getState }) => {
        dispatch(gettingRoles())
        var myHeaders = new Headers()
        const token = localStorage.getItem('token')
        myHeaders.append("Authorization", `Bearer ${JSON.parse(token)}`)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl+"/showAllRoles", requestOptions)
            .then(response =>  response.json())
            .then(result => {
                if(result.success)
                {
                    dispatch(rolesResponse(result?.roles))
                }
                else     
                Alert("error","something went wrong while getting roles")    
            })
            .catch(eror => { 
                Alert("error","something went wrong while getting roles")    
                dispatch(rolesError()) 
            })
    }
)

const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        rolesData: [],
        loadingRoles: false,
        rolesError: false,
    },
    reducers: {
        gettingRoles: (state, action) => {
            state.rolesData = [];
            state.loadingRoles = true;
            state.rolesError = false;
        },
        rolesResponse: (state, action) => {
            state.rolesData = action.payload;
            state.loadingRoles = false;
            state.rolesError = false;
        },
        rolesError: (state, action) => {
            state.rolesData = [];
            state.loadingRoles = false;
            state.rolesError = true
        },
        rolesClear: (state, action) => {
            state.rolesData = [];
            state.loadingRoles = false;
            state.rolesError = false
        },
    },
})

// Action creators are generated for each case reducer function
export const {gettingRoles, rolesResponse, rolesError, rolesClear} = rolesSlice.actions;
export default rolesSlice.reducer;