import { domainUrl } from "../../shared"
import { Alert } from "../"

const saveAddAppointment = (data) => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    const token = localStorage.getItem('token')
    myHeaders.append("Authorization", `Bearer ${JSON.parse(token)}`)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
    }
    let url = domainUrl + (data?.id ? '/updateAppointment' : '/createAppointment')
    return (
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    Alert("success", `Appointment ${data?.id ? 'updated' : 'saved'} successfully`)
                    return 1
                }
                else
                    throw new Error('error')
            })
            .catch(error => {
                Alert("error", `something went wrong while ${data?.id ? 'updating' : 'saving'} appointment!!!`)
                return 0
            })
    )
}

export { saveAddAppointment }