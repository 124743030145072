import { useState, useEffect, useRef } from "react"
import { Button, Modal, Space } from "antd"
import {PrinterOutlined} from "@ant-design/icons"
import { ReadyToPrint } from "../ReadyToPrint"
import { HairForm } from "./azeem"
import "./index.css"
import { apiCalls } from "../../../shared"
import { data } from "./data"

const PrintForm= ({state, handleChange})=>{
    return (
        <HairForm {...{state, handleChange}}/>
    )
}

const MedicalHistory = ({ visible, appointmentID, onClose}) => {

  const [state, setState]= useState(null)
  const [loading, setLoading]= useState(false)
  const print= useRef()
  const clickOnPrint= _ => print.current.click()

    useEffect(()=>{
      if(appointmentID)
        getMedicalHistory()
      else
        setState({...data})
    }, [appointmentID])
    const getMedicalHistory= async () => {
      let result= await apiCalls?.appointment?.viewMedicalHistory(appointmentID)
      if(result)
        setState({...result})
      else
        setState({...data})
    }
    const handleChange= (key, value)=> (setState({...state, [key]: value}))
    const saveUpdateMedicalHistory= async () => {
      setLoading(true)
      await apiCalls?.appointment?.saveUpdateMedicalHistory({...state, appId: appointmentID})
      setLoading(false)
    }
    const closeDrawer = () => onClose(0)
    return (
        <Modal
          title= "Medical History"
          centered
          width={"793.92px"}
          onCancel={closeDrawer}
          open={visible}
          footer={
            <Space>
              <Button 
                type="primary" 
                onClick={clickOnPrint}
                loading={loading}
                className="btn-primary-blue"
                icon={<PrinterOutlined/>}
              >
                Print Medical History 
              </Button>
              <Button 
                type="primary" 
                onClick={saveUpdateMedicalHistory}
                loading={loading}
              >
                Save Medical History
              </Button>
            </Space>
          }
        >
          <ReadyToPrint
              name= 'Medical History'
              print= {print}
              Component={<PrintForm {...{state, handleChange}}/>}
          />
        </Modal>
    )
}

export default MedicalHistory
