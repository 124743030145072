const DashboardCardsData = [
    {
        title: 'Users',
        count: 0,
        icon: 'users.png',
    },

    {
        title: 'Clients',
        count: 0,
        icon: 'clients.png',
    },

    {
        title1: "Today's FUEs",
        title2: "Tomorrow FUEs",
        count1: 0,
        count2: 0,
        icon: 'appointments.png',
    },
    {
        title1: "Today's PRPs",
        title2: "Tomorrow PRPs",
        count1: 0,
        count2: 0,
        icon: 'prp.png',
    },
]
const AppointmentsStatisticsData = [
    {
        title1: 'FUE Appointments',
        title2: 'Pending',
        title3: 'Complete',
        title4: 'Cancel',
        count1: 0,
        coun2: 0,
        count3: 0,
        count4: 0,
        icon: 'appointments-128.png',
    },
    {
        title1: 'PRP Sessions',
        title2: 'Pending',
        title3: 'Complete',
        title4: 'Cancel',
        count1: 0,
        count2: 0,
        count3: 0,
        count4: 0,
        icon: 'prp-128.png',
    },
]
export { DashboardCardsData, AppointmentsStatisticsData }