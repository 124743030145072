import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';

export const getCategories = createAsyncThunk('admins',
    async (data, { dispatch, getState }) => {

        let token=JSON.parse(localStorage.getItem("token"))?.token
        dispatch(gettingCategories())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/categories`, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(categoriesResponse(result))
            })
            .catch(error => { dispatch(categoriesError()) })
    }
)

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        categories: null,
        loadingCategories: false,
        categoriesError: null,
    },
    reducers: {
        gettingCategories: (state, action) => {
            state.categories = null;
            state.loadingCategories= true;
            state.categoriesError = false;
        },
        categoriesResponse: (state, action) => {
            state.categories = action.payload;
            state.loadingCategories = false;
            state.categoriesError = false;
        },
        categoriesError: (state, action) => {
            state.categories = null;
            state.loadingCategories = false;
            state.categoriesError = true
        },
        categoriesClear: (state, action) => {
            state.categories = null;
            state.loadingCategories = false;
            state.categoriesError = false
        },
    }
})

export const {gettingCategories, categoriesResponse, categoriesError, categoriesClear} = categoriesSlice.actions;
export default categoriesSlice.reducer;