import {Form, Typography, Image, Checkbox} from 'antd';
import { MyRadioGroup } from './';
export const RecipentArea = ({name,label,required,message,value, options, ...props}) => {
  return (
    <Form.Item
        name={name}
        rules={[
            {
            required,
            message,
            },
        ]}
        label={<Typography.Text strong>{label}</Typography.Text>}
        >
            {/* <div>
                <Image 
                    src='/assets/recipent-area.png'
                    alt='hair plus int'
                    style={{width:'300px'}}
                    />
            </div> */}
            <Checkbox.Group 
                options={options} 
                value={[2,3]}
                {...props}
            />
    </Form.Item>  
  )
}