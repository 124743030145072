import { message } from "antd";
import { domainUrl } from "../constants/apiconstans/domanUrl";
import { checkAuthorization } from "../helpers/Authorization";
const updateAppointment=(object)=>{
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("content-type", `application/json`);
    myHeaders.append("Authorization", userToken)
    var requestOptions = {
    method: 'post',
    headers: myHeaders,
    redirect: 'follow',
    body:JSON.stringify(object)
    }
    return (
        fetch(domainUrl+'/updateAppStatus', requestOptions)
        .then(response => response.json())
        .then(result =>{
          if(result?.success)
            {
              message.success('appointment status updated successfully')
              return 1
            }
          else 
          throw "error while updating appointment status"
        })
        .catch(error =>{
            message.error(error)
            return 0
        })
    )
}
const saveUpdateMedicalHistory=(data)=>{
  const {userToken}= checkAuthorization()
  var myHeaders = new Headers();
  myHeaders.append("content-type", `application/json`)
  myHeaders.append("Authorization", userToken)
  var requestOptions = {
  method: 'post',
  headers: myHeaders,
  redirect: 'follow',
  body:JSON.stringify(data)
  }
  let endPoint= data?.id ? '/updateMedicalHistory' : '/createMedicalHistory'

  return (
      fetch(domainUrl+endPoint, requestOptions)
      .then(response => response.json())
      .then(result =>{
        if(result?.success)
          {
            message.success(result?.message)
            return 1
          }
        else 
          throw result?.message
      })
      .catch(error =>{
          message.error(error)
          return 0
      })
  )
}
const viewMedicalHistory=(ID)=>{
  const {userToken}= checkAuthorization()
  var myHeaders = new Headers()
  myHeaders.append("Authorization", userToken)
  var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
  }
  return (
      fetch(domainUrl+`/viewMedicalHistoryByAppId/${ID}`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        if(result?.success)
          return result?.medicalHistories?.length ? result?.medicalHistories[0] : 0
        else 
          throw result?.message
      })
      .catch(error =>{
          message.error(error)
          return 0
      })
  )
}
const saveUpdatePrescribedMedicines=(data)=>{
  const {userToken}= checkAuthorization()
  var myHeaders = new Headers();
  myHeaders.append("content-type", `application/json`)
  myHeaders.append("Authorization", userToken)
  var requestOptions = {
  method: 'post',
  headers: myHeaders,
  redirect: 'follow',
  body:JSON.stringify(data)
  }
  let endPoint= data?.id ? '/updatePrescribeMedicines' : '/createPrescribeMedicines'

  return (
      fetch(domainUrl+endPoint, requestOptions)
      .then(response => response.json())
      .then(result =>{
        if(result?.success)
          {
            message.success(result?.message)
            return 1
          }
        else 
          throw result?.message
      })
      .catch(error =>{
          message.error(error)
          return 0
      })
  )
}
const viewPrescribedMedicines=(data)=>{
  const {userToken}= checkAuthorization()
  var myHeaders = new Headers()
  myHeaders.append("content-type", `application/json`)
  myHeaders.append("Authorization", userToken)
  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow',
  body: JSON.stringify(data)
  }
  return (
      fetch(domainUrl+`/viewPrescribeMedicines`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        if(result?.success)
          return result?.data?.length ? result?.data[0] : 0
        else 
          throw result?.message
      })
      .catch(error =>{
          // message.error(error)
          return 0
      })
  )
}
const appointment= {
    updateAppointment,
    saveUpdateMedicalHistory,
    viewMedicalHistory,
    saveUpdatePrescribedMedicines,
    viewPrescribedMedicines
}
export {appointment}