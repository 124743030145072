import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization, currentMonthStartEnd} from '../../../helpers'
import { message } from 'antd'

export const loadAllPRPAppointments = createAsyncThunk('PRPAppointments',
    async (obj, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingPRPAppointments())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", obj?.pageNo)
        myHeaders.append("recordsPerPage", obj?.pageSize)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({...obj?.data})
        };
        fetch(domainUrl + `/viewPRPAppointments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    let list = result?.result?.filter((obj, index, array) => {
                        return array.findIndex(item => item?.id === obj?.id) === index
                      })
                    dispatch(PRPappointmentsResponse([...list]))
                    dispatch(setPages({pageNo: obj?.pageNo, pageSize: obj?.pageSize, totalRecords: result?.totalRecords}))
                } 
                else
                    throw 'error while getting PRP Sessions'
            })
            .catch(error => {
                dispatch(PRPappointmentsClear());
                message.error(error)
            })
    }
)
const PRPAppointmentsSlice = createSlice({
    name: 'allPRPAppointments',
    initialState: {
        PRPappointments: [],
        loadingPRPAppointments: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            name: '',
            sessionDate: currentMonthStartEnd()
        }
    },
    reducers: {
        gettingPRPAppointments: (state, action) => {
            state.loadingPRPAppointments = true;
        },
        PRPappointmentsResponse: (state, action) => {
            state.PRPappointments = action.payload;
            state.loadingPRPAppointments = false;
        },
        PRPappointmentsClear: (state, action) => {
            state.PRPappointments = [];
            state.loadingPRPAppointments = false;
        },
        changePRPAppointmentFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        }
    },
})
export const { 
    gettingPRPAppointments, 
    PRPappointmentsResponse, 
    PRPappointmentsClear, 
    changePRPAppointmentFilters, 
    setPages 
    } = PRPAppointmentsSlice.actions;
export default PRPAppointmentsSlice.reducer;