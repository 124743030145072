import { createSlice, } from '@reduxjs/toolkit'

const PrintReceiptDataSlice = createSlice({
    name: 'printReceiptData',
    initialState: {
        data: {},
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        }
    },
})
export const { setData } = PrintReceiptDataSlice.actions;
export default PrintReceiptDataSlice.reducer;