export const data={
    hairFallStartingAge: '',
    isFamilyDisease: 'Y',
    anyPreviousTreatments: 'None.',
    generalHealth: 'Healthy',
    isDiabetes: 'N',
    isAsthma: 'N',
    isIHD: 'N',
    isHypertension: 'N',
    isHepatitis: 'N',
    isJaundice: 'N',
    isEpilepsy: 'N',
    isSkinConditions:null,
    localAnesthesiaHistory: '',
    anyCurrentInfection: 'N',
    other: null,
    hospitalAdmissions: 'None.',
    previousSurgeries: 'None.',
    trauma: '',
    antibioticCourse: 'None.',
    pulse: null,
    BP: null,
    BSR: null,
    anyPreOps: null,
    HIV: null,
    hepatitis: null,
    anyAspirin: 'N',
    anySteroids: 'N',
    anyAntiCoagulants: 'N',
    anyOthers: 'N',
    allergies: 'None.',
    bleedingTendencies: 'None.',
    smoking: 'Yes',
    otherSubstanceUses: 'None.',
    recentTravelHistory: 'Yes',
    upComingTravel: '',
    note: null
}