import moment from 'moment'
import { convertTo12HourFormat } from './HelperFuntions'
const SimpleDateFormat= (date)=>{
    return date ? moment(date).format('MMM Do YYYY') : null
}
const LLFormat = (date) => {
    return (
        date ?
            moment(date).format('LL') : moment().format('LL')
    )
}
const LLFormatWithTime = (date) => {
    return (
        date ?
            moment(date).format('LL HH:mm:ss') : moment().format('LL HH:mm:ss')
    )
}

const LLFormatWithTimeNoDefault = (date) => {
    return (
        date ?
            moment(date).format('LL HH:mm:ss') : "Date Not Set Yet"
    )
}


const FormattedDateTime = (dateInfo) => {
    console.log('info:',dateInfo)
    return (
        !dateInfo?.date || !dateInfo?.time ? ''
        :moment(dateInfo?.date).format('LL') + " " + convertTo12HourFormat(moment(dateInfo?.time).format('HH:mm')) 
    )
}

const simpleDate = (date) => {
    return (date ? moment(date) : null)
}
const momentDate= (date) => {
    return date ? moment(date) : null
}
const onlyDate = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : null
}
const onlyDateWithoutNull = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
}
const onlyTime = (date) => {
    return date ? moment(date).format("HH:mm") : null
}
const dateWithTime= (date) => {
    return date ? moment(date).format("YYYY-MM-DDTHH:mm") : null
}
const currentMonthStartEnd= ()=>{
    let date= new Date()
    let currentMonth= date?.getMonth()
    let currentYear= date?.getFullYear()
    return [onlyDate(moment([currentYear, currentMonth])), onlyDate(moment(moment([currentYear, currentMonth])).endOf('month'))]
}
const addDays= (date, days)=>{
    return date ? moment(date).add(days, 'days').format('YYYY-MM-DD') : ''
}
function getNextMonthDateWithSundayHandling(date) {
    date = date.add(1, 'month').add(14, 'days')
    if (date.day() === 0) 
        date= date.add(1, 'day')
    return date.format('YYYY-MM-DD')
  }
export { 
    SimpleDateFormat,
    LLFormat, 
    LLFormatWithTime, 
    simpleDate, 
    momentDate, 
    onlyDate, 
    onlyDateWithoutNull,
    onlyTime, 
    dateWithTime,
    LLFormatWithTimeNoDefault, 
    FormattedDateTime,
    currentMonthStartEnd,
    addDays,
    getNextMonthDateWithSundayHandling
}