import { message } from "antd";
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import { checkAuthorization } from "../../helpers"

  const addRole = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", userToken)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl + `/createRoles`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success("Role added successfully")
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
  }
  
  const updateRole = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", userToken)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl + `/updateRole`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success("Role updated successfully")
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
  }
  
  const deleteRole = (ID) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", userToken)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
    return (
        fetch(domainUrl + `/deleteRole/${ID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success("Role deleted successfully")
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
  }
const roles={
    addRole,
    updateRole,
    deleteRole
}
export {roles}