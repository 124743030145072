import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getAllFeatureLogo = createAsyncThunk('featureLogo',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingFeatureLogo())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/viewAllFeatureLogo`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                {
                    let tempArr=[...result?.FeatureLogos?.map(data=>({...data,file:JSON.parse(data?.file)}))]
                    dispatch(featureLogoResponse(tempArr))
                }
                else
                {
                    dispatch(featureLogoClear())
                    Alert("error",result?.message) 
                }
            })
            .catch(error => {
                dispatch(featureLogoClear())
                Alert("error","error while loading feature logos") 
            })
    }
)

const featureLogoSlice = createSlice({
    name: 'featureLogo',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingFeatureLogo: (state, action) => {
            state.loading = true;
        },
        featureLogoResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        featureLogoClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingFeatureLogo, featureLogoResponse, featureLogoClear} = featureLogoSlice.actions
export default featureLogoSlice.reducer