import { Form, Typography } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import './Forms/index.css'
export const CitiesTextEditor = ({withoutForm, name, label, disabled, required, message, value, onChange, ...props }) => {
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],
    ['blockquote'],
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];
  return (
    <>
      {
        withoutForm ?
        <ReactQuill
          theme="snow"
          onChange={onChange}
          disabled={disabled || false}
          modules={
            {
              toolbar: toolbarOptions
            }
          }
          {...props}
        /> :
        <Form.Item
          name={name}
          label={<Typography.Text strong>{label}</Typography.Text>}
          rules={[
            {
              required,
              message,
            },
          ]}
          >
          <ReactQuill
            theme="snow"
            onChange={onChange}
            disabled={disabled || false}
            modules={
              {
                toolbar: toolbarOptions
              }
            }
            {...props}
          />
        </Form.Item>
      }
    </>
  )
}