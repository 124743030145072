import React, { useEffect, useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Typography, Skeleton} from 'antd'
import { Chart } from "react-google-charts"
import moment from 'moment'
import "./index.css"
import { actionsApi } from '../../shared'

const CalendarChart = () => {
  const dispatch= useDispatch()
  const { fueAppointmentCalendar } = useSelector(state => state?.dashboard)
  const [state, setState] = useState([
    [
        { type: "date", id: "Date" },
        { type: "number", id: "Won/Loss" },
    ]
  ])
  const fontName=`-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif`
  useEffect(()=>{
    dispatch(actionsApi?.loadFUEAppointmentCalendar(2023))
  }, [])
  useEffect(() => {
      if (fueAppointmentCalendar?.length) {
          let tempArr = []
          for(let i=0; i< fueAppointmentCalendar?.length; i++)
          {
            for (let key in fueAppointmentCalendar[i]) {
              if(!fueAppointmentCalendar[i][key])
                continue
              tempArr.push([new Date(moment(key).format("YYYY-MM-DD")), fueAppointmentCalendar[i][key]])
            }   
          }
          setState([...state, ...tempArr])
      }
  }, [fueAppointmentCalendar])


   const options = {
    title: null,
    calendar: {
        monthLabel: {
            fontName,
            color: 'black'
        },
        underMonthSpace: 10,
        underYearSpace: 10,
        // yearLabel: {
        //     fontName,
        //     color: 'black'
        // },
        focusedCellColor: {
            stroke: 'rgb(127, 182, 145)',
          },
    },
    colorAxis:{
        minValue: 0,  
        colors: ['rgb(202, 247, 217)', 'rgb(127, 182, 145)']
    }
  };

  return (
    <>
    {
      !fueAppointmentCalendar ?
      <Skeleton.Button 
          active 
          size='large' 
          shape='square'
          block
          style={{width:'100%', height:'288px'}}
          className='radius-3'
      />
      :
      <div className='border-box-0'>
          <Typography.Title level={5} className='light-grey-bg-brand px-3 py-2'>
            Follicular Unit Transplantation(FUE) Appointments - 2023
          </Typography.Title>
          <div>
            <Chart
                chartType="Calendar"
                height="190px"
                data={state}
                options={options}
            />
          </div>
      </div>
    }
    </>
  )
}

export {CalendarChart}