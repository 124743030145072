import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { message } from 'antd'
import { checkAuthorization, currentMonthStartEnd } from '../../../helpers'


export const loadAllFUEAppointments = createAsyncThunk('FUEAppointments',
    async (obj, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingFUEAppointments())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", obj?.pageNo)
        myHeaders.append("recordsPerPage", obj?.pageSize)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({...obj?.data})
        };
        fetch(domainUrl + `/viewfueAppointments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(FUEappointmentsResponse(result?.result))
                    dispatch(setPages({pageNo: obj?.pageNo, pageSize: obj?.pageSize, totalRecords: result?.totalRecords}))
                } 
                else
                    throw 'error while getting FUE Appointments'
            })
            .catch(error => { 
                dispatch(FUEappointmentsClear()); 
                message.error(error)
            })
    }
)
const FUEAppointmentsSlice = createSlice({
    name: 'allFUEAppointments',
    initialState: {
        FUEappointments: [],
        loadingFUEAppointments: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            name: '',
            appointmentDate: currentMonthStartEnd(),
            fueAppointmentStatus: 1,
        }
    },
    reducers: {
        gettingFUEAppointments: (state, action) => {
            state.loadingFUEAppointments = true;
        },
        FUEappointmentsResponse: (state, action) => {
            state.FUEappointments = action.payload;
            state.loadingFUEAppointments = false;
        },
        FUEappointmentsClear: (state, action) => {
            state.FUEappointments = [];
            state.loadingFUEAppointments = false;
        },
        changeFUEAppointmentFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { gettingFUEAppointments, FUEappointmentsResponse, 
    FUEappointmentsClear, changeFUEAppointmentFilters, setPages } = FUEAppointmentsSlice.actions;
export default FUEAppointmentsSlice.reducer;