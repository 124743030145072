import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getAllUsers = createAsyncThunk('users',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingUsers())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/getAllUser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(usersResponse(result?.users))
                else
                {
                    dispatch(usersClear())
                    Alert("error",result?.message) 
                }
            })
            .catch(error => {
                dispatch(usersClear())
                Alert("error","error while loading users") 
            })
    }
)

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        data: [],
        loadingUsers: false
    },
    reducers: {
        gettingUsers: (state, action) => {
            state.loadingUsers = true;
        },
        usersResponse: (state, action) => {
            state.data = action.payload;
            state.loadingUsers= false;
        },
        usersClear: (state, action) => {
            state.data = [];
            state.loadingUsers = false;
        },
    },
})

export const {gettingUsers, usersResponse, usersClear} = usersSlice.actions;
export default usersSlice.reducer;