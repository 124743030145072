import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization } from '../../../helpers'
import { message } from 'antd'
export const getAllMedicines = createAsyncThunk('Medicines',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingMedicines())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `${userToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/viewAllMedication`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(medicinesResponse(result?.medications))
                else
                    throw result?.error
            })
            .catch(error => {
                dispatch(medicinesClear())
                message.error(error)
            })
    }
)

const medicinesSlice = createSlice({
    name: 'medicines',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingMedicines: (state) => {
            state.loading = true
        },
        medicinesResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        medicinesClear: (state) => {
            state.data = []
            state.loading = false
        },
    },
})

export const {gettingMedicines, medicinesResponse, medicinesClear} = medicinesSlice.actions;
export default medicinesSlice.reducer;