import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../components';
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
export const loadAppointmentByID = createAsyncThunk('Appointments',
    async (ID, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/singleViewAppoinment/${ID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(setAllDetails(result?.Appoinment[0]))
                }
                else {
                    throw new Error('error')
                }
            })
            .catch(error => { Alert("error", "error while loading appointments") })
    }
)
const AppointmentDetailsSlice = createSlice({
    name: 'appointmentDetails',
    initialState: {
        id: null,
        treatmentTypeIDs: [1, 2],
        recipentArea: [2, 3],
        geneticDisease: 1,
        stage: 2,
        estimatedgrafts: '',
        estimatedFUECost: '',
        estimatedPRPCost: '',
        estimatedDate: null,
        callAgain: null,
        clientPriority: 2,
        note: '',
        client_id: null,
        PRPSessions: [],
        FUEAppointmentDate: null,
        FUEAppointmentTime: null,
        totalFUECost: 0,
        advanceFUEAmount: 0,
        balanceFUEAmount: 0,
        totalPRPCost: 0,
        advancePRPAmount: 0,
        balancePRPAmount: 0,
        isDraft: false,
        fueAppointmentStatus:null,
        prpAppointmentStatus: null,
        addedBy: null,
        updatedBy: null,
    },
    reducers: {
        setSteps: (state, action) => {
            state.id = action.payload.id
            state.client_id = action.payload.client_id
            state.treatmentTypeIDs = action.payload.treatmentTypeIDs
            state.geneticDisease = action.payload.geneticDisease
            state.recipentArea = action.payload.recipentArea
            
            state.stage = action.payload.stage
            state.estimatedgrafts = action.payload.estimatedgrafts
            state.estimatedFUECost = action.payload.estimatedFUECost
            state.estimatedPRPCost = action.payload.estimatedPRPCost
            state.estimatedDate = action.payload.estimatedDate
            state.callAgain = action.payload.callAgain
            state.clientPriority = action.payload.clientPriority
            state.note = action.payload.note
            state.isDraft = action.payload.isDraft
            
            state.PRPSessions = action?.payload?.PRPSessions?.map(session => ({ ...session, time: session?.date + "T" + session?.time }))
            state.FUEAppointmentDate = action.payload.FUEAppointmentDate
            state.FUEAppointmentTime = action.payload.FUEAppointmentDate + 'T' + action.payload.FUEAppointmentTime
            state.totalFUECost = action.payload.totalFUECost
            state.advanceFUEAmount = action.payload.advanceFUEAmount
            state.balanceFUEAmount = action.payload.balanceFUEAmount
            state.totalPRPCost = action.payload.totalPRPCost
            state.advancePRPAmount = action.payload.advancePRPAmount
            state.balancePRPAmount = action.payload.balancePRPAmount
            state.fueAppointmentStatus = action.payload.fueAppointmentStatus
            state.prpAppointmentStatus = action.payload.prpAppointmentStatus
            state.addedBy = action.payload.addedBy
            state.updatedBy = action.payload.updatedBy
        },
        setStep2: (state, action) => {
            state.treatmentTypeIDs = action.payload.treatmentTypeIDs
            state.geneticDisease = action.payload.geneticDisease
            state.recipentArea = action.payload.recipentArea
            state.stage = action.payload.stage
            state.estimatedgrafts = action.payload.estimatedgrafts
            state.estimatedFUECost = action.payload.estimatedFUECost
            state.estimatedPRPCost = action.payload.estimatedPRPCost
            state.estimatedDate = action.payload.estimatedDate
            state.callAgain = action.payload.callAgain
            state.clientPriority = action.payload.clientPriority
            state.note = action.payload.note
            state.isDraft = true
            state.client_id = action.payload.client_id
        },
        setStep3: (state, action) => {
            state.treatmentTypeIDs = action.payload.treatmentTypeIDs
            state.recipentArea = action.payload.recipentArea
            state.PRPSessions = action.payload.PRPSessions
            state.FUEAppointmentDate = action.payload.FUEAppointmentDate
            state.FUEAppointmentTime = action.payload.FUEAppointmentTime
            state.totalFUECost = action.payload.totalFUECost
            state.advanceFUEAmount = action.payload.advanceFUEAmount
            state.balanceFUEAmount = action.payload.balanceFUEAmount
            state.totalPRPCost = action.payload.totalPRPCost
            state.advancePRPAmount = action.payload.advancePRPAmount
            state.balancePRPAmount = action.payload.balancePRPAmount
            state.note = action.payload.note
            state.isDraft = action.payload.isDraft
            state.fueAppointmentStatus = action.payload.fueAppointmentStatus
            state.prpAppointmentStatus = action.payload.prpAppointmentStatus
        },
        clearSteps: (state, action) => {
            state.id = null
            state.treatmentTypeIDs = [1, 2]
            state.geneticDisease = 1
            state.recipentArea = [2, 3]
            state.stage = 2
            state.estimatedgrafts = ''
            state.estimatedFUECost = ''
            state.estimatedPRPCost = ''
            state.estimatedDate = null
            state.callAgain = null
            state.clientPriority = 2
            state.note = ''
            state.client_id = null
            state.PRPSessions = []
            state.FUEAppointmentDate = null
            state.FUEAppointmentTime = null
            state.totalFUECost = 0
            state.advanceFUEAmount = 0
            state.balanceFUEAmount = 0
            state.totalPRPCost = 0
            state.advancePRPAmount = 0
            state.balancePRPAmount = 0
            state.app_status = 1
            state.isDraft = true
            state.fueAppointmentStatus = null
            state.prpAppointmentStatus = null
            state.addedBy = null
            state.updatedBy = null
        },
        setAllDetails: (state, action) => {
            state.id = action.payload.id
            state.treatmentTypeIDs = action.payload.treatmentTypeIDs
            state.recipentArea = action.payload.recipentArea
            state.geneticDisease = action.payload.geneticDisease
            state.stage = Number(action.payload.stage)
            state.estimatedgrafts = action.payload.estimatedgrafts
            state.estimatedFUECost = action.payload.estimatedFUECost
            state.estimatedPRPCost = action.payload.estimatedPRPCost
            state.estimatedDate = action.payload.estimatedDate
            state.callAgain = action.payload.callAgain
            state.clientPriority = Number(action.payload.clientPriority)
            state.note = action.payload.note
            state.client_id = action.payload.Client_info.id
            state.isDraft = action.payload.isDraft
            
            state.PRPSessions = action?.payload?.PRPSessions?.map((session) => {
                return {
                    ...session,
                    date: session.date,
                    time: session.date + " " + session.time
                }
            })
            state.FUEAppointmentDate = action.payload.FUEAppointmentDate
            state.FUEAppointmentTime = action.payload.FUEAppointmentDate + 'T' + action.payload.FUEAppointmentTime
            state.totalFUECost = action.payload.totalFUECost
            state.advanceFUEAmount = action.payload.advanceFUEAmount
            state.balanceFUEAmount = action.payload.balanceFUEAmount
            state.totalPRPCost = action.payload.totalPRPCost
            state.advancePRPAmount = action.payload.advancePRPAmount
            state.balancePRPAmount = action.payload.balancePRPAmount
            state.fueAppointmentStatus = action.payload.fueAppointmentStatus
            state.prpAppointmentStatus = action.payload.prpAppointmentStatus
            state.addedBy = action.payload.created_by
            state.updatedBy = action.payload.updatedBy
        }
    },
})
export const { setSteps, setStep2, setStep3, clearSteps, setAllDetails } = AppointmentDetailsSlice.actions;
export default AppointmentDetailsSlice.reducer;