import { useState, useEffect, useRef} from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Modal, Form, Space} from "antd"
import { ReadyToPrint } from "../ReadyToPrint"
import { HairForm } from "./azeem"
import "../index.css"
import { apiCalls, actionsApi} from "../../../shared"
import { PrinterOutlined } from "@ant-design/icons"

const PrintForm= ({state, form, medicinesLookup, handleChange})=>{
    return (
        <HairForm {...{state, form, medicinesLookup, handleChange}}/>
    )
}

const After14DayPrescribeMedicines = ({ visible, clientInfo, appointmentID, day, onClose}) => {

  const appDispatcher = useDispatch()
  const [form]= Form.useForm()
  const {data} = useSelector((state) => state?.medicines)
  const [medicinesLookup, setMedicinesLookup]= useState([])
  const [state, setState]= useState(null)
  const [loading, setLoading]= useState(false)
  const print= useRef()
  const clickOnPrint= _ => print.current.click()

    useEffect(()=>{
      if(appointmentID)
        viewPrescribedMedicines()
      else
        setState(null)
    }, [appointmentID])

    useEffect(() => {
        appDispatcher(actionsApi?.getAllMedicines())
    }, [appDispatcher])

    useEffect(() => {
        if(data?.length)
        {
            setMedicinesLookup([...data?.filter(medicine => medicine?.isFourteen)?.map(medicine => ({...medicine, name: medicine?.medicationName}))
            ])
        }
    }, [data])
    const viewPrescribedMedicines= async () => {
      let result= await apiCalls?.appointment?.viewPrescribedMedicines({appointmentID, days: day})
      if(result)
        {
          form.setFieldsValue({medicines: result?.medicines?.map(medicine => medicine?.id)})
          setState({...result, name: clientInfo?.name, age: clientInfo?.age})
        }
      else
        {
          form.resetFields()
          setState({name: clientInfo?.name, age: clientInfo?.age})
        }
    }
    const handleChange= (key, value, index)=>{
      if(key==='intakeMethod')
          setState({
              ...state,
              medicines: state?.medicines?.map((medicine, i)=>{
                  if(i===index)
                      return ({...medicine, intakeMethod:value})
                  return medicine
              })
          })
      else
          setState({
              ...state,
              medicines: value?.map(medicineID=>{
                  let medicine=state?.medicines?.find(fin=>fin?.id===medicineID)
                  if(medicine)
                      return medicine
                  medicine= medicinesLookup?.find(fin => fin?.id===medicineID)
                  return medicine
              })
          })
    }
    const saveUpdatePrescribedMedicines= async () => {
      setLoading(true)
      await apiCalls?.appointment?.saveUpdatePrescribedMedicines({...state, appointmentID, days: day})
      setLoading(false)
      onClose()
    }
    return (
        <>
          <Modal
            title= "Prescribe Medicines"
            centered
            width={"793.92px"}
            onCancel={onClose}
            open={visible}
            footer={
              <Space>
                <Button 
                  type="primary" 
                  className="btn-primary-purple"
                  onClick={clickOnPrint}
                >
                  <PrinterOutlined/> Take a Print
                </Button>
                <Button 
                  type="primary" 
                  onClick={saveUpdatePrescribedMedicines}
                  loading={loading}
                >
                  {state?.id ? 'Update' : 'Save'} Prescribed Medicines
                </Button>
              </Space>
            }
          >
            <ReadyToPrint
              name= 'Prescribed Medicines'
              print= {print}
              Component={<PrintForm {...{state, form, medicinesLookup, handleChange}}/>}
            />
          </Modal>
        </>
    )
}

export {After14DayPrescribeMedicines}
