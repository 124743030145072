import { Row, Col, Form, Button, Space, Tabs, Typography, message, Dropdown} from "antd";
import { useState, useEffect} from "react";
import {
  MyRadioGroup,
  CitiesTextEditor,
  RecipentArea,
} from "../"
import { saveAddAppointment } from "./apiCalls"
import { useSelector, useDispatch } from "react-redux";
import {
  actionsApi,
  treatmentTypeLookup,
  recipentAreaLookup,
  onlyDate,
  onlyTime,
  dateWithTime,
} from "../../shared";
import PayReceiptModal from "../Printer/Receipt"
import { setData } from "../../shared/redux/action/printReceiptModal"
import {  momentDate} from "../../shared"
import { PreOperationInstructions } from "../Printer"
import PRPSection from "./PRPSection";
import FUESection from "./FUESection";
const {Text}= Typography



const Step3 = ({ onClose, nextStep, style, editID, isDraft, isDashboardDraft, isPRP, isDashboardPRP, isFUE, isDashboardFUE, userData }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { appointmentDetails, login } = useSelector((state) => state)
  const { filters: draftFilters, pagination: draftPagination } = useSelector(state => state?.draft)
  const {filters, pagination } = useSelector((state) => state?.allFUEAppointments)
  const {filters: prpFilter, pagination: prpPagination } = useSelector(state => state?.allPRPAppointments)

  const [isFueAppointment, setIsFueAppointment]= useState(false)
  const [isPrpAppointment, setIsPrpAppointment]= useState(false)
  const [loading, setLoading] = useState(false)
  const [PRPSessions, setPRPSessions] = useState([]);
  const [visiblePrintModal, setVisiblePrintModal] = useState(false)
  const [visiblePreOpDetails, setVisiblePreOpDetails] = useState(false)
  console.log("app:", appointmentDetails)
  const [activeKey, setActiveKey]= useState('section-1')

  useEffect(()=>{
    if(isPRP)
      setActiveKey('section-2')
  }, [isPRP])
  useEffect(() => {
    if (appointmentDetails && form)
      {
        form.setFieldsValue({
          ...appointmentDetails,
          FUEAppointmentDate: momentDate(appointmentDetails?.FUEAppointmentDate),
          FUEAppointmentTime: momentDate(appointmentDetails?.FUEAppointmentTime)
        })
        if (appointmentDetails?.PRPSessions?.length)
          setPRPSessions([
            ...appointmentDetails?.PRPSessions?.map(session => ({
                ...session,
                date: momentDate(session?.date),
                time: momentDate(session?.time),
            }))
          ])
        setIsPrpAppointment(appointmentDetails?.treatmentTypeIDs?.includes(2))
        setIsFueAppointment(appointmentDetails?.treatmentTypeIDs?.includes(1))
      }
  }, [appointmentDetails, form])

  const sections= [
    { 
      label: <Text strong>FUE Details</Text>, 
      key: 'section-1', 
      children: <FUESection {...{form, isFueAppointment, PRPSessions, setPRPSessions}}/>
    },
    { 
      label: <Text strong>PRP Sessions Details</Text>, 
      key: 'section-2', 
      children: <PRPSection {...{form, PRPSessions, setPRPSessions}}/>
    }
  ]

  const prepareData= (back)=>{
    let data= form.getFieldsValue()
    if(data?.treatmentTypeIDs?.includes(2) && !PRPSessions?.length)
      {
        message.error('PRP treatment is checked for client. Please add PRP sessions.')
        return 0
      }
    let tempObj={}
    if(back)
    {
      tempObj={
        PRPSessions: PRPSessions?.map((session) => ({
          ...session,
          date: onlyDate(session?.date),
          time: dateWithTime(session?.time),
        })),
        FUEAppointmentDate: onlyDate(data?.FUEAppointmentDate),
        FUEAppointmentTime: dateWithTime(data?.FUEAppointmentTime),
      }
    }
    else
    {
      tempObj={
        PRPSessions: PRPSessions?.map((session) => ({
          ...session,
          date: onlyDate(session?.date),
          time: onlyTime(session?.time),
        })),
        FUEAppointmentDate: onlyDate(data?.FUEAppointmentDate),
        FUEAppointmentTime: onlyTime(data?.FUEAppointmentTime),
        isDraft: false,
        fueAppointmentStatus: data?.treatmentTypeIDs?.includes(1),
        prpAppointmentStatus: data?.treatmentTypeIDs?.includes(2),
      }
    }
    return ({
      ...appointmentDetails,
      ...form.getFieldsValue(),
      ...tempObj
    })
  }

  const back = () => {
    dispatch(actionsApi?.setStep3(prepareData(true)))
    nextStep()
  }

  const save = async () => {
    let data = prepareData()
    if(!data) return
    setLoading(true)
    if (isPRP) {
      data = {
        ...data,
        updatedBy: login.loginData.name,
      };
    }
    let result = await saveAddAppointment(data)
    setLoading(false)
    if (result) 
    {
      if(isDraft)
      {
          if(isDashboardDraft)
              dispatch(actionsApi?.loadDashboard())
          else
          {
              let data= {
                  ...draftFilters,
                  callAgain: draftFilters?.callAgain || null,
              }
              dispatch(actionsApi?.loadAllDraft({data, ...draftPagination}))
          }
      }
      if(isFUE)
      {
        if(isDashboardFUE)
          dispatch(actionsApi?.loadDashboard())
        else
        {
          let data= {
            ...filters,
            fromDate: filters?.appointmentDate?.length ? filters?.appointmentDate[0] : null,
            toDate: filters?.appointmentDate?.length ? filters?.appointmentDate[1] : null,
          }
          dispatch(actionsApi?.loadAllFUEAppointments({data, ...pagination}))
        }
      }
      if(isPRP)
      {
        if(isDashboardPRP)
          dispatch(actionsApi?.loadDashboard())
        else
          {
            let data= {
              ...prpFilter,
              fromDate: prpFilter?.sessionDate?.length ? prpFilter?.sessionDate[0] : null,
              toDate: prpFilter?.sessionDate?.length ? prpFilter?.sessionDate[1] : null,
            }
            dispatch(actionsApi?.loadAllPRPAppointments({data, ...prpPagination}))
          }
          
      }
    }
      onClose()
  }

 
 
  const getPrintData = () => {
    let data = {
      ...userData,
      ...form.getFieldsValue(),
      prpSessions: [...PRPSessions],
    };
    dispatch(setData(data))
  };

  return (
    <div style={style}>
      <Form layout="vertical" form={form} initialValues={true} onFinish={save}>
        <Row gutter={16}>
          <Col span={24}>
            <MyRadioGroup
              name="treatmentTypeIDs"
              label="Treatment type"
              required
              message="Choose these option"
              value={form.getFieldValue("treatmentTypeIDs")}
              options={treatmentTypeLookup}
            />
          </Col>
          <Col span={24}>
            <RecipentArea
              name="recipentArea"
              label="Recipent Area"
              required
              message="please select"
              value={form.getFieldValue("recipentArea")}
              options={recipentAreaLookup}
            />
          </Col>
          <Col span={24}>
            <Tabs
              activeKey= {activeKey}
              items={ sections}
              onChange={key => setActiveKey(key)}
              className="px-3 border-grey radius-3 no-shadow mb-3"
            />
          </Col>
          <Col span={24}>
            <CitiesTextEditor
              name="note"
              label="Any Note"
              placeholder="patient is very bald..."
              value={form.getFieldValue("note")}
            />
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Button onClick={back}>Back</Button>
            <Button
              onClick={() => {
                getPrintData();
                setVisiblePrintModal(true);
              }}
              className="btn-primary-yellow"
            >
              Print Receipt
            </Button>
            <Button 
              type="primary"
              onClick={() => setVisiblePreOpDetails(true)}
              className="btn-primary-grey"
              >
              Pre-Operation Instructions
            </Button>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={loading}
              >
              {
                editID ? 'Update Appointment': 'Add Appointment'
              }
            </Button>
            
          </Space>
        </div>
      </Form>
      <PayReceiptModal
        visible={visiblePrintModal}
        onClose={() =>  setVisiblePrintModal(false)}
      />
      <PreOperationInstructions
        visible={visiblePreOpDetails}
        onClose={() => {
          setVisiblePreOpDetails(false);
        }}
      />
    </div>
  )
}

export default Step3