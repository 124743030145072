import {CheckOutlined, ClockCircleFilled, CloseOutlined, DeleteOutlined} from "@ant-design/icons"

const treatmentTypeLookup = [
  { value: 1, label: 'FUE Hair Transplant' },
  { value: 2, label: 'PRP treatment' },
  { value: 3, label: 'Prescription Medications' },
]
const treatmentTypeLookup2 = [
  { id: 1, name: 'FUE Hair Transplant' },
  { id: 2, name: 'PRP treatment' },
  { id: 3, name: 'Prescription Medications' },
]
const recipentAreaLookup = [
  { value: 1, label: 'Temples' },
  { value: 2, label: 'Front' },
  { value: 3, label: 'Mid scalp' },
  { value: 4, label: 'Crown/vertex' }
]
const appointmentStatusLookup = [
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Completed' },
  { id: 3, name: 'Cancel' },
]
const prpSessionLookup = [
  { id: 2, name: 'Pending' },
  { id: 3, name: 'Completed' },
  { id: 4, name: 'Cancel' },
]
const priorityLookup = [
  { id: 1, name: 'Low' },
  { id: 2, name: 'Normal' },
  { id: 3, name: 'High' },
  { id: 4, name: 'Urgent' },
]
const priorityLookup2 = [
  { value: 1, label: 'Low' },
  { value: 2, label: 'Normal' },
  { value: 3, label: 'High' },
  { value: 4, label: 'Urgent' },
]
const sourceLookup = [
  { id: 5, name: 'Referred by friend' },
  { id: 1, name: 'Facebook' },
  { id: 2, name: 'Twitter' },
  { id: 3, name: 'Instagram' },
  { id: 4, name: 'Other' },
]

const biChoiseLookup = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
]
const PRPSessionStatusLookup = [
  { value: 1, icon: <ClockCircleFilled/>, label: 'pending'},
  { value: 2, icon: <CheckOutlined/> , label: 'Session done'},
  { value: 3, icon: <CloseOutlined/>, label: 'cancel Session'},
  { value: 4, icon: <DeleteOutlined/>, label: 'Delete Session'}
]

export {
  treatmentTypeLookup,
  treatmentTypeLookup2,
  recipentAreaLookup,
  appointmentStatusLookup,
  prpSessionLookup,
  priorityLookup,
  priorityLookup2,
  sourceLookup,
  biChoiseLookup,
  PRPSessionStatusLookup
}