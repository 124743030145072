import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import moment from "moment"
export const loadTodaysAppointments = createAsyncThunk('dashboardAppointments',
    async (_, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingDashobardAppointments('loadingTodaysAppointments'))
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", "1")
        myHeaders.append("recordsPerPage", "100")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                name: '',
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
                fueAppointmentStatus: 1,
            })
        }
        fetch(domainUrl + `/viewfueAppointments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    let temp = {
                        data: result?.result,
                        response: 'todaysAppointments',
                        loading: 'loadingTodaysAppointments'
                    }
                    dispatch(dashboardAppointmentsResponse(temp))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(dashboardAppointmentsClear({response: 'todaysAppointments', loading: 'loadingTodaysAppointments'}))
                message.error(error)
            })
    }
)
export const loadTomorrowAppointments = createAsyncThunk('dashboardTomorrowAppointments',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingDashobardAppointments('loadingTomorrowAppointments'))
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", "1")
        myHeaders.append("recordsPerPage", "100")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                name: '',
                fromDate: moment().add("days", 1).format("YYYY-MM-DD"),
                toDate: moment().add("days", 1).format("YYYY-MM-DD"),
                fueAppointmentStatus: 1,
            })
        }
        fetch(domainUrl + `/viewfueAppointments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    let temp = {
                        data: result?.result,
                        response: 'tomorrowAppointments',
                        loading: 'loadingTomorrowAppointments'
                    }
                    dispatch(dashboardAppointmentsResponse(temp))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(dashboardAppointmentsClear({response: 'tomorrowAppointments', loading: 'loadingTomorrowAppointments'}))
                message.error(error)
            })
    }
)
const DashboardAppointmentsSlice = createSlice({
    name: 'dashboardAppointments',
    initialState: {
        todaysAppointments: [],
        tomorrowAppointments: [],
        loadingTodaysAppointments: false,
        loadingTomorrowAppointments: false,
    },
    reducers: {
        gettingDashobardAppointments: (state, action) => {
            state[action.payload] = true;
        },
        dashboardAppointmentsResponse: (state, action) => {
            state[action.payload.response] = action.payload.data;
            state[action.payload.loading] = false;
        },
        dashboardAppointmentsClear: (state, action) => {
            state[action.payload.response] = [];
            state[action.payload.loading] = false;
        }
    },
})
export const { gettingDashobardAppointments, dashboardAppointmentsResponse, dashboardAppointmentsClear } = DashboardAppointmentsSlice.actions;
export default DashboardAppointmentsSlice.reducer;