import {Radio, Tooltip} from 'antd';
export const CitiesRadioGroupWithoutForm = ({value, options, ...props}) => {
    return (
        <Radio.Group 
            value={value} 
            buttonStyle="solid" 
            {...props}
            >
            {
                options?.map((opt,o)=>
                    <Tooltip title={opt?.label}  key={o}>
                        <Radio.Button value={opt?.value}>
                            {
                                opt?.icon
                            }
                        </Radio.Button>
                    </Tooltip>
                )
            }
        </Radio.Group>
    )
}