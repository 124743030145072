import { roles } from "./Roles and Premissions"
import { clients } from "./Clients"
import { appointment } from "./Appointment"
import { medications } from "./Medicines"
const apiCalls={
    roles,
    clients,
    appointment,
    medications
}
export * from "./dashboard"
export * from "./Clients"
export * from "./Youtube"
export * from "./PRPSessions"
export {apiCalls}