import { useRef } from "react";
import { Modal, Button} from "antd"
import {PrinterOutlined} from "@ant-design/icons"
import { ReadyToPrint } from "../ReadyToPrint"
import { HairForm } from "./azeem";
import "./index.css"

const PrintForm= ({prpSessions, fueAppointmentDate})=>{
    return (
        <HairForm prpSessions={prpSessions} fueAppointmentDate={fueAppointmentDate}/>
    )
}
const After13DaysInstructions = ({ visible, fueAppointmentDate, prpSessions, onClose}) => {

  const print= useRef()
  const clickOnPrint= _ => print.current.click() 
  const closeDrawer = () => onClose(0)
  return (
      <Modal
        title= {<span className="right-to-left font-family-urdu">تیرہ دن کے بعد کی ہدایات</span>}
        centered
        destroyOnClose
        width={"793.92px"}
        onCancel={closeDrawer}
        open={visible}
        footer={
          <Button
            type="primary"
            onClick={clickOnPrint}
            icon={<PrinterOutlined/>}
            className="btn-primary-grey"
          >
            Print After 13 days Instructions
          </Button>
        }
      >
        <ReadyToPrint
            name= 'After 13 days Instructions'
            print= {print}
            Component={<PrintForm prpSessions={prpSessions} fueAppointmentDate={fueAppointmentDate}/>}
        />
      </Modal>
  )
}

export {After13DaysInstructions}
