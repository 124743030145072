import { Row, Col, Space, Button, Form } from "antd"
import { useEffect } from "react";
import {
    CitiesInput,
    CitiesDateTimePicker,
    CitiesRadioGroup,
    MyRadioGroup,
    CitiesTextEditor,
    CustomizedRadios,
    RecipentArea
} from "../"
import moment from "moment";
import { saveAddAppointment } from "./apiCalls";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    actionsApi,
    treatmentTypeLookup,
    recipentAreaLookup,
    momentDate,
    onlyDate
} from '../../shared'
const Step2 = ({ clientID, onClose, nextStep, style, isDraft, isDashboardDraft, isPRP, isDashboardPRP, isFUE, isDashboardFUE}) => {
    
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { appointmentDetails } = useSelector(state => state)
    const { filters: draftFilters, pagination: draftPagination } = useSelector(state => state?.draft)
    const {filters, pagination } = useSelector((state) => state?.allFUEAppointments)
    const {filters: prpFilter, pagination: prpPagination } = useSelector(state => state?.allPRPAppointments)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(form && appointmentDetails)
            form.setFieldsValue({
                ...appointmentDetails,
                estimatedDate: momentDate(appointmentDetails?.estimatedDate) ,
                callAgain: momentDate(appointmentDetails?.callAgain),
            })
    }, [appointmentDetails, form])

    const save = async () => {
        let data = form.getFieldsValue(
            [
                'treatmentTypeIDs', 'recipentArea', 'geneticDisease', 'stage', 'estimatedgrafts', 'estimatedFUECost',
                'estimatedPRPCost', 'estimatedDate', 'callAgain', 'clientPriority', 'note',
                'continue'
            ])
        data = {
            ...appointmentDetails,
            ...data,
            client_id: clientID,
            isDraft: true,
            estimatedDate: onlyDate(data?.estimatedDate),
            callAgain: onlyDate(data?.callAgain)
        }
        if (data?.continue) {
            dispatch(actionsApi?.setStep2({ ...data }))
            nextStep(1)
            return
        }
        setLoading(true)
        let result = await saveAddAppointment(data)
        setLoading(false)
        if (result) 
        {
            if(isDraft)
            {
                if(isDashboardDraft)
                    dispatch(actionsApi?.loadDashboard())
                else
                {
                    let data= {
                        ...draftFilters,
                        callAgain: draftFilters?.callAgain || null,
                    }
                    dispatch(actionsApi?.loadAllDraft({data, ...draftPagination}))
                }
            }
            if(isFUE)
                {
                    if(isDashboardFUE)
                    dispatch(actionsApi?.loadDashboard())
                    else
                    {
                        let data= {
                            ...filters,
                            fromDate: filters?.appointmentDate?.length ? filters?.appointmentDate[0] : null,
                            toDate: filters?.appointmentDate?.length ? filters?.appointmentDate[1] : null,
                        }
                        dispatch(actionsApi?.loadAllFUEAppointments({data, ...pagination}))
                    }
                }
            if(isPRP)
                {
                    if(isDashboardPRP)
                    dispatch(actionsApi?.loadDashboard())
                    else
                    {
                        let data= {
                        ...prpFilter,
                        fromDate: prpFilter?.sessionDate?.length ? prpFilter?.sessionDate[0] : null,
                        toDate: prpFilter?.sessionDate?.length ? prpFilter?.sessionDate[1] : null,
                        }
                        dispatch(actionsApi?.loadAllPRPAppointments({data, ...prpPagination}))
                    }
                    
                }
        }
        onClose()
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={save}
                onFinishFailed={() => {
                    console.log(form.getFieldsValue())
                }}
                style={style}
            >
                <Row gutter={32}>
                    <Col span={24}>
                        <MyRadioGroup
                            name='treatmentTypeIDs'
                            label=''
                            required={true}
                            message='Choose these option'
                            value={form.getFieldValue('treatmentTypeIDs')}
                            options={treatmentTypeLookup}
                        />
                    </Col>
                    <Col span={24}>
                        <CitiesRadioGroup
                            name='geneticDisease'
                            label='HEREDITARY BALDNESS (GENETIC)'
                            value={form.getFieldValue('geneticDisease')}
                            options={[
                                { name: 'Yes', value: 1 },
                                { name: 'No', value: 2 },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <RecipentArea
                            name='recipentArea'
                            label='Recipent Area'
                            required={true}
                            message='please select'
                            value={form.getFieldValue("recipentArea")}
                            options={recipentAreaLookup}
                        />
                    </Col>
                    <Col span={24}>
                        <CustomizedRadios
                            name='stage'
                            label='According to the chart below which stage of hair loss most closely resembles to the stage of hair loss?'
                            value={form.getFieldValue('stage')}
                        />
                    </Col>
                    <Col span={8} >
                        <CitiesInput
                            name='estimatedgrafts'
                            label='Estimated Grafts'
                            required={true}
                            message='Please enter estimated graph'
                            placeholder='e.g 3000'
                            value={form.getFieldValue('estimatedgrafts')}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesInput
                            addonBefore="Rs"
                            name='estimatedFUECost'
                            label='Estimated FUE Cost'
                            required={true}
                            message='Please enter cost'
                            placeholder='e.g 75000'
                            value={form.getFieldValue('estimatedFUECost')}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesInput
                            addonBefore="Rs"
                            name='estimatedPRPCost'
                            label='Estimated PRP cost'
                            required={true}
                            message='Please enter estimated prp cost'
                            placeholder='e.g 0'
                            value={form.getFieldValue('estimatedPRPCost')}
                        />
                    </Col>
                    <Col span={16}>
                        <CitiesDateTimePicker
                            datePicker
                            name='estimatedDate'
                            label='In how many days he/she wants to have hair transplant surgery'
                            required={true}
                            message='Please enter surgery date'
                            placeholder='10/12/2022'
                            value={form.getFieldValue('estimatedDate')}
                            disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return current && current < moment(customDate, "YYYY-MM-DD")
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <CitiesDateTimePicker
                            datePicker
                            name='callAgain'
                            label='When to call again'
                            required={true}
                            message='Please enter surgery time'
                            placeholder=''
                            value={form.getFieldValue('callAgain')}
                            disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return current && current < moment(customDate, "YYYY-MM-DD")
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <CitiesRadioGroup
                            name='clientPriority'
                            label='Client Priority'
                            value={form.getFieldValue('clientPriority')}
                            options={[
                                { name: 'Low', value: 1 },
                                { name: 'Normal', value: 2 },
                                { name: 'High', value: 3 },
                                { name: 'Urgent', value: 4 },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <CitiesTextEditor
                            name='note'
                            label='Any Note'
                            placeholder='patient is very bald...'
                            value={form.getFieldValue('note')}
                        />
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>
                        <Button
                            onClick={() => { nextStep(null) }}
                        >
                            Back
                        </Button>
                        <Button
                            danger
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            onClick={() => { form.setFieldsValue({ ...form.getFieldsValue(), continue: false }) }}
                        >
                            Save to draft
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { 
                                form.setFieldsValue({ ...form.getFieldsValue(), continue: true }) 
                                save()
                            }}
                        >
                            continue
                        </Button>
                    </Space>
                </div>
            </Form>
        </>
    )
}
export default Step2