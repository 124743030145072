import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';

export const loadSoicalMedias = createAsyncThunk('SoicalMedias',
    async (data, { dispatch, getState }) => {
        // const tokenString = localStorage.getItem('@user');
        // const userToken = JSON.parse(tokenString);
        dispatch(gettingSoicalMedias())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer 4|8JbFZftBfuSTrv0q0LZySRQBc0UgIK88csl0xvB3`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/socialMedia`, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(SoicalMediasResponse(result))
            })
            .catch(error => { dispatch(SoicalMediasError()) })
    }
)

const SoicalMediasSlice = createSlice({
    name: 'SoicalMedias',
    initialState: {
        soicalMedias: null,
        loadingSoicalMedias: false,
        soicalMediasError: null,
      
    },
    reducers: {
        gettingSoicalMedias: (state, action) => {
            state.soicalMedias = null;
            state.loadingSoicalMedias = true;
            state.soicalMediasError = false;
        },
        SoicalMediasResponse: (state, action) => {
            state.soicalMedias = action.payload;
            state.loadingSoicalMedias = false;
            state.soicalMediasError = false;
        },
        SoicalMediasError: (state, action) => {
            state.soicalMedias = null;
            state.loadingSoicalMedias = false;
            state.soicalMediasError = true
        },
        SoicalMediasClear: (state, action) => {
            state.soicalMedias = null;
            state.loadingSoicalMedias = false;
            state.soicalMediasError = false
        },
    },
})

// Action creators are generated for each case reducer function
export const {gettingSoicalMedias, SoicalMediasResponse, SoicalMediasError, SoicalMediasClear} = SoicalMediasSlice.actions;
export default SoicalMediasSlice.reducer;