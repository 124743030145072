import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getAllGoogleReviews = createAsyncThunk('googleReviews',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingGoogleReviews())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/viewAllClientReview`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(googleReviewsResponse(result?.reviews))
                else
                {
                    dispatch(googleReviewsClear())
                    Alert("error",result?.message) 
                }
            })
            .catch(error => {
                dispatch(googleReviewsClear())
                Alert("error","error while loading google reviews") 
            })
    }
)

const googleReviewsSlice = createSlice({
    name: 'googleReviews',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingGoogleReviews: (state, action) => {
            state.loading = true;
        },
        googleReviewsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        googleReviewsClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingGoogleReviews, googleReviewsResponse, googleReviewsClear} = googleReviewsSlice.actions
export default googleReviewsSlice.reducer