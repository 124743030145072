import { Typography, Space } from "antd"
import { FormattedDateTime, LLFormatWithTime, onlyDate, onlyTime,  } from "../../../shared/helpers/DateFunctions"
import { formatCurrency, generateRandomAlphanumeric, useShareSelector } from "../../../shared"
import "./index.css"
const { Text, Title } = Typography

const PayReceipt = ({data2}) => {
    const { data } = useShareSelector(state => state.printReceiptData)
    console.log("data:", data)
    console.log("data:", data2)
    return (
        <div className="justify-center">
            <Space direction='vertical' size={30} className="pay-receipt-page width-100">
                <Space 
                    className="justify-end width-100"
                    size={2}
                >
                    <Text strong>
                        Date:
                    </Text>
                    <span>{LLFormatWithTime()}</span>
                </Space>
                <Space direction="vertical" className="width-100">
                    <Text strong>Hair Plus International Hair Transplant Centre</Text>
                    <Space>
                        <Text strong>
                            S.no #
                        </Text>
                        <Text>
                            {
                                generateRandomAlphanumeric()
                            }
                        </Text>
                    </Space>
                    <Space>
                        <Text strong>
                            Name:
                        </Text>
                        <Text>
                            {data?.name}
                        </Text>
                    </Space>
                    <Space>
                        <Text strong>
                            Age:
                        </Text>
                        <Text>
                            {data?.age}
                        </Text>
                    </Space>
                    <Space>
                        <Text strong>
                            Treatment Type:
                        </Text>
                        <Text>
                            {
                                data?.treatmentTypeIDs?.includes(1) ? "Follicular Unit Transplantation(FUE)" : ""
                            }
                            {
                                data?.treatmentTypeIDs?.includes(2) ? (data?.treatmentTypeIDs?.includes(1)?', PRP Sessions': 'PRP Sessions') : ''
                            }
                        </Text>
                    </Space>
                    {
                        data?.treatmentTypeIDs?.includes(1) ?
                        <Space>
                            <Text strong>
                                FUE Hair Transplant Appointment Date:
                            </Text>
                            <Text>
                                {
                                    FormattedDateTime({date: data?.FUEAppointmentDate, time: data?.FUEAppointmentTime})
                                }
                            </Text>
                        </Space>
                        :<></>
                    }
                    {
                        data?.treatmentTypeIDs?.includes(2) ?
                        <Space>
                            <Text strong>
                                Next PRP Session:
                            </Text>
                            <Text>
                                <Space>
                                    {
                                        data?.prpSessions?.map( (dateInfo, index) => (
                                                <span>{FormattedDateTime({date: dateInfo?.date, time: dateInfo?.time}) + (index<(data?.prpSessions?.length-1) ? ", " : '')}</span>
                                            )
                                        )
                                    }
                                </Space>
                            </Text>
                        </Space>
                        :<></>
                    }
                </Space>
                {
                    data?.treatmentTypeIDs?.includes(1) ?
                    <table className="print-receipt-table">
                        <thead>
                            <tr>
                                <th width='40%'>FUE Hair Transplant Cost</th>
                                <th>Advance</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> {formatCurrency(data?.totalFUECost ||0)} PKR</td>
                                <td> {formatCurrency(data?.advanceFUEAmount || 0)} PKR</td>
                                <td> {formatCurrency(data?.balanceFUEAmount || 0)} PKR</td>
                            </tr>
                        </tbody>
                    </table>
                    :<></>
                }
                {
                    data?.treatmentTypeIDs?.includes(2) ?
                    <table className="print-receipt-table">
                        <thead>
                            <tr>
                                <th width='40%'>PRP Sessions Cost</th>
                                <th>Advance</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatCurrency(data?.totalPRPCost || 0)} PKR</td>
                                <td> {formatCurrency(data?.advancePRPAmount || 0)} PKR</td>
                                <td> {formatCurrency(data?.balancePRPAmount || 0)} PKR</td>
                            </tr>
                        </tbody>
                    </table>
                    :<></>
                }
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space direction="vertical">
                        <Text strong>
                            Note:
                        </Text>
                        <ul style={{ paddingLeft: '39px' }}>
                            <li>Advance is not Refundable</li>
                            <li>Be on Time</li>
                        </ul>
                    </Space>
                    <div className="justify-end">
                        <Text strong>
                            This is computer generated receipt no signature required.
                        </Text>
                    </div>
                </Space>
            </Space>
        </div>
    )
}
export { PayReceipt }