import { useEffect, useState } from 'react';
import { Modal, Typography } from 'antd'
import { Steps } from 'antd';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { useDispatch } from 'react-redux';
import { actionsApi } from '../../shared';
import { TopBar } from './Topbar';
const { Title } = Typography

export const Addappointment = ({ visible, editID, clientInfo, onClose, isPRP, isDashboardPRP, isFUE, isDashboardFUE, isDraft, isDashboardDraft}) => {
  const [current, setCurrent] = useState(1)
  const dispatch = useDispatch()
  const steps = [
    {
      title: <Title level={5}>Client Details</Title>
    },
    {
      title: <Title level={5}>Draft</Title>
    },
    {
      title: <Title level={5}>Set Appointment</Title>
    },
    {
      title: <Title level={5}>Mark Completed</Title>
    },
  ]
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  useEffect(() => {
    if (editID) {
      if (isPRP || isFUE) 
        setCurrent(2)
      else
        setCurrent(1)
    }
  }, [editID, isPRP, isFUE, isDraft])

  const closeModal = () => {
    dispatch(actionsApi?.clearSteps())
    setCurrent(0)
    onClose()
  }

  return (
    <div>
      <Modal
        title="Add Appointment"
        centered
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        width={1000}
        destroyOnClose
      >
        {/* <TopBar/> */}
        <div>
          <Steps
            progressDot
            current={current}
            items={items}
            labelPlacement="vertical"
            className='mb-3'
          />
          <div>
            {
              current === 0 ?
                <Step1
                  clientInfo={clientInfo}
                  nextStep={() => { setCurrent(current + 1) }}
                /> :
                current === 1 ?
                  <Step2
                    clientID={clientInfo?.id}
                    onClose={closeModal}
                    nextStep={
                      (data) => {
                        if (data)
                          setCurrent(current + 1)
                        else
                          setCurrent(current - 1)
                      }
                    }
                    isDraft={isDraft}
                    isDashboardDraft={isDashboardDraft}
                    isFUE= {isFUE}
                    isDashboardFUE= {isDashboardFUE}
                    isPRP={isPRP}
                    isDashboardPRP= {isDashboardPRP}
                    editID={editID}
                  /> :
                  current === 2 ?
                    <Step3
                      onClose={closeModal}
                      nextStep={() => { setCurrent(current - 1) }}
                      isDraft={isDraft}
                      isDashboardDraft={isDashboardDraft}
                      isFUE= {isFUE}
                      isDashboardFUE= {isDashboardFUE}
                      isPRP={isPRP}
                      isDashboardPRP= {isDashboardPRP}
                      editID={editID}
                      userData={{ "name": clientInfo?.name, "age": clientInfo?.age }}
                    /> :
                    ''
            }
          </div>

        </div>
      </Modal>
    </div>
  )
}