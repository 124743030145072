import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';

export const getPlaces = createAsyncThunk('places',
    async (data, { dispatch, getState }) => {

        let token=JSON.parse(localStorage.getItem("token"))?.token
        dispatch(gettingPlaces())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/places`, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(placesResponse(result))
            })
            .catch(error => { dispatch(placesError()) })
    }
)

const placesSlice = createSlice({
    name: 'places',
    initialState: {
        places: null,
        loadingPlaces: false,
        placesError: null,
    },
    reducers: {
        gettingPlaces: (state, action) => {
            state.places = null;
            state.loadingPlaces= true;
            state.placesError = false;
        },
        placesResponse: (state, action) => {
            state.places = action.payload;
            state.loadingPlaces = false;
            state.placesError = false;
        },
        placesError: (state, action) => {
            state.places = null;
            state.loadingPlaces = false;
            state.placesError = true
        },
        placesClear: (state, action) => {
            state.places = null;
            state.loadingPlaces = false;
            state.placesError = false
        },
    }
})

export const {gettingPlaces, placesResponse, placesError, placesClear} = placesSlice.actions;
export default placesSlice.reducer;