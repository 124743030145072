import { Typography, Space, Input, Form, Row, Col } from 'antd'
import { CitiesSelect } from '../../'
import { LLFormatWithTime} from '../../../shared'
import './index.css'

const { Text } = Typography
const HairForm = ({state, form, medicinesLookup, handleChange}) => {

  return (
    <>
        <Form 
            layout="vertical"
            form={form}
            initialValues={{
                remember: true,
            }}
            className='prescribed-medicines-form'
        >
            <Row gutter={16}>
                <Col span={12}>
                    <CitiesSelect 
                        mode='multiple'
                        name="medicines"
                        label="Medicines" 
                        required 
                        message='please select atleast one medicine' 
                        placeholder='e.g Cap Cefspan 400 mg' 
                        onChange={(value)=> handleChange('medicines', value)}
                        options={medicinesLookup}
                    />
                </Col>
            </Row>
        </Form>
        <div className='justify-center w-100'>
            <div className='after-14-day-prescribed-medicines-page width-100'>
                <header></header>
                <div>
                    <Space className='width-100' direction='vertical' size={8}>
                        <div className='space-between client-info'  style={{width:'700px'}}>
                            <div className='space-d' style={{width:'300px'}}>
                                <p className='my-0'>
                                    Name:
                                </p>
                                <Input 
                                    size='small'
                                    value={state?.name}
                                    />
                            </div>
                            <div className='space-d' style={{width:'90px'}}>
                                <p className='my-0'>
                                    Age:
                                </p>
                                <Input 
                                    size='small'
                                    value={state?.age}
                                    />
                            </div>
                            <div className='space-d' style={{width:'280px'}}>
                                <p className='my-0'>
                                    Date:
                                </p>
                                <Input 
                                    size='small'
                                    value={LLFormatWithTime()}
                                    />
                            </div>
                        </div>
                        <Space 
                            direction='vertical'
                            className='width-100 pt-3'
                            size={20}
                        >
                            {
                                state?.medicines?.map((medicine, index)=>
                                <Space 
                                    direction='vertical'
                                    className='width-100'
                                    size={5}
                                >
                                    <div className='space-between'>
                                        <Text strong>
                                            {
                                                (index+1) + ". "
                                            }
                                            {
                                                medicine?.name
                                            }
                                            {
                                                medicine?.unit ? ' - '+ medicine?.unit : ''
                                            }
                                        </Text>
                                        <Text strong>
                                            {
                                                medicine?.quantity
                                            }
                                        </Text>
                                    </div>
                                    <p className='my-0'>
                                        {
                                            medicine?.intakeMethod
                                        }
                                    </p>
                                    {
                                        medicine?.otherInstructions ?
                                        <Space direction='vertical' size={1}>
                                            <Text strong>Dosage: (For External Use Only)</Text>
                                            <p className='my-0'>
                                                {
                                                    medicine?.otherInstructions
                                                }
                                            </p>
                                        </Space>
                                        :<></>
                                    }
                                </Space>
                                )
                            }
                        </Space>
                    </Space>
                </div>
                <footer></footer>
            </div>
        </div>
    </>
  )
}

export {HairForm}