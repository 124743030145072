import React from 'react'
import { Chart } from "react-google-charts"
import { Skeleton, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';


const ClinicStatistics = () => {
    const { data } = useSelector(state => state?.dashboard)
    const [state, setState] = useState([])
    useEffect(() => {
        if (data) {
            let tempArr = [['Date','Clients', "Appointments"]]
            for (let key in data?.clientByDates) {
                tempArr.push(
                    [
                        moment(key).format("ddd Do"),
                        data?.clientByDates[key] || 0,
                        data?.appointmentByLastDays[key] || 0,
                    ]
                )
            }
            setState([...tempArr])
        }
    }, [data])
    console.log("state:", state)
    const options = {
        legend: { position: "none" }
      }
    return (
        <>
        {
            !data ?
            <Skeleton.Button 
                active 
                size='large' 
                shape='square'
                block
                style={{width:'100%', height:'328px'}}
                className='radius-3'
            />
            :
            <div className='border-box-0'>
                <Typography.Title level={5} className='light-grey-bg px-3 py-2'>
                    Client and Appointments
                </Typography.Title>
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="280px"
                    data={state}
                    options={options}
                    />
            </div>
        }
        </>
    )
}

export {ClinicStatistics}