import { useEffect } from "react"
import Login from "../pages/auth"
import { isUnAuthorize } from "../shared"

const Protected = ({ children }) => {

    useEffect(()=>{
        if(isUnAuthorize())
            localStorage.clear()
    },[])

      if (isUnAuthorize()) {
        return <Login/>
    
      }
      return children
}
export default Protected;
