import {Image} from 'antd'
export const HairLossStage = ({stage}) => {
    const genders=[
        {
          gender:'Men',
          conditions:[
            'male1.png',
            'male2.png',
            'male3.png',
            'male4.png',
            'male5.png',
            'male6.png',
          ]
        },
        {
          gender:'Woman',
          conditions:[
            'female1.png',
            'female2.png',
            'female3.png'
          ]
        }
      ]
    return (
        <Image 
            src={'assets/images/'+(genders[parseInt(stage)<=6?0:1]?.conditions[parseInt(stage)<=6?(stage-1):stage-7])}
            alt='hair plus international'
            preview={false}
            />
  )
}