import { message } from "antd";
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import { checkAuthorization } from "../../helpers";

const addUpdateClient = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    let url = data?.client_id ? '/updateClient' : '/createClient'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const deleteClient = (ID) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", userToken)
    var requestOptions = {
      method: 'Delete',
      headers: myHeaders,
      redirect: 'follow'
    }
    return (
        fetch(domainUrl + '/deleteClient/' + ID, requestOptions)
        .then(response => response.json())
        .then(result => {
        if (result?.success) {
            message.success(result?.message)
            return 1
        }
        else 
            throw  result?.message
        })
        .catch(error => {
        message.error(error)
        return 0
        })
    )
  }
const clients={
    addUpdateClient,
    deleteClient
}
export {clients}