import {Row, Col, Space, Button, Typography} from "antd"
import {PlusOutlined} from "@ant-design/icons"
export const ModuleInfo=({name, onClick})=>{
    return (
        <Row>
            <Col span={24}>
                <Space>
                    <Typography.Title level={4}>
                        {
                            name
                        }
                    </Typography.Title>
                    <Button type="primary" shape="circle" size='small' 
                        icon={<PlusOutlined />} style={{transform:'translateY(-3px)'}}
                        onClick={onClick}
                        />
                </Space>
            </Col>
        </Row>
    )
}