import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization } from '../../../helpers'
import { message } from 'antd'
export const getUnseenNotificationsCount= createAsyncThunk('unseenNotifications',
    async (_, { dispatch, getState }) => {
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/unseenNotificationCounter`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(unseenNoticationsCount(result?.data))
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(clearUnseenNoticationsCount())
                message.error(error)
            })
    }
)
export const showAllNotifications= createAsyncThunk('Notifications',
    async (_, { dispatch, getState }) => {
        const {userToken}= checkAuthorization()
        dispatch(gettingNotifications())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/viewNotification`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(notificationsResponse(result?.notification))
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(notificationsClear())
                message.error(error)
            })
    }
)
export const clearNotifications= createAsyncThunk('clearNotifications',
    async (_, { dispatch, getState }) => {
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/clearNotifications`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                {
                    message.success(result?.message)
                    dispatch(notificationsClear())
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(notificationsClear())
                message.error(error)
            })
    }
)

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        data: [],
        count:0,
        loading: false,
    },
    reducers: {
        gettingNotifications: (state) => {
            state.loading = true;
        },
        notificationsResponse: (state, action) => {
            state.data = action.payload
            state.loading= false;
        },
        notificationsClear: (state) => {
            state.data = []
            state.loading = false
        },
        unseenNoticationsCount: (state, action) =>{
            state.count= action.payload
        },
        clearUnseenNoticationsCount: (state) =>{
            state.count= 0
        }
    }
})

export const {
    gettingNotifications, 
    notificationsResponse, 
    notificationsClear,
    unseenNoticationsCount,
    clearUnseenNoticationsCount
} = notificationsSlice.actions
export default notificationsSlice.reducer