import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import {checkAuthorization, onlyDateWithoutNull} from '../../../helpers'
import { message } from 'antd'

export const loadAllDraft = createAsyncThunk('Draft',

    async (obj, { dispatch, getState }) => {
        
        const {userToken}= checkAuthorization()
        dispatch(gettingDraft())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", obj?.pageNo)
        myHeaders.append("recordsPerPage", obj?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({...obj?.data})
        };
        fetch(domainUrl + `/viewDraftAppointment`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(draftResponse(result?.result?.reverse()))
                    dispatch(setPages({pageNo: obj?.pageNo, pageSize: obj?.pageSize, totalRecords: result?.totalRecords}))
                } 
                else
                throw "error while getting drafts"
            })
            .catch(error => {
                dispatch(draftClear())
                message.error(error)
            })
    }
)
const draftSlice = createSlice({
    name: 'draft', 
    initialState: {
        data: [],
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            name: '',
            callAgain: onlyDateWithoutNull(),
            priorityID: 3
        }
    },
    reducers: {
        gettingDraft: (state, action) => {
            state.loading = true;
        },
        draftResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        draftClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
        changeDraftFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { gettingDraft, draftResponse, draftClear, changeDraftFilters, setPages } = draftSlice.actions;
export default draftSlice.reducer;