import { Dropdown} from "antd"
import { ActionButton } from "../actionButton"
const DropDownButton= ({title, icon, menu, onClick, ...props})=>{
    return (
        <Dropdown 
            menu={menu} 
            arrow={{
                pointAtCenter: true,
              }}
            placement="topCenter"
            {...props}
            >
            <ActionButton
                title={title}
                onClick={onClick}
                icon={icon}
                />
      </Dropdown>
    )
}
export {DropDownButton}