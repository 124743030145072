import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { message } from 'antd';
import { checkAuthorization } from '../../../helpers';
import dashboard from '../dashboard';
export const login = createAsyncThunk('login',
    async (data, { dispatch, getState }) => {
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl + "/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    localStorage.setItem('token', JSON.stringify(result?.user?.token))
                    let role = result?.user?.role
                    let userRole = {
                        id: role?.id,
                        name: role?.roleName
                    }
                    localStorage.setItem('userRole', JSON.stringify(userRole))
                    localStorage.setItem('premissions', JSON.stringify(role?.permissions))
                    dispatch(loginResponse(result?.user))
                    window.location.href='/'
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(loginClear()) 
            })
    }
)

export const AuthCheck = createAsyncThunk('LoginCheck',
    async (data, { dispatch, getState }) => {
        const { userToken }= checkAuthorization()
        dispatch(gettingLoginCheck())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/loginCheck`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    const user = localStorage.getItem('userRole');
                    dispatch(loginResponse(JSON.parse(user)))
                }
                else 
                throw result?.message
            })
            .catch(error => {
                dispatch(loginClear())
                message.error(error)
            })
    }
)


const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loginData: null,
        loading: false,
        LoginCheckloading: false
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        loginResponse: (state, action) => {
            state.loginData = action.payload;
            state.loading = false;
            state.LoginCheckloading = false
        },
        loginClear: state => {
            state.loginData = null
            state.loading = false
            state.LoginCheckloading = false
        },
        gettingLoginCheck: state => {
            state.LoginCheckloading = true
        }
    },
})

export const { getting, loginResponse, loginClear, gettingLoginCheck } = loginSlice.actions;
export default loginSlice.reducer;