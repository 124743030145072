import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../components';
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import moment from 'moment'

export const loadAllYoutubeVideos = createAsyncThunk('YoutubeVideos',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        dispatch(gettingYoutubeVideos())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/viewAllYoutubeVideo`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(youtubeVideosResponse([...result?.videos]))
                else
                {
                    dispatch(youtubeVideosClear())
                    throw  'error'
                }
            })
            .catch(error => {dispatch(youtubeVideosClear()); Alert("error","error while loading YoutubeVideos")})
    }
)
const YoutubeVideosSlice = createSlice({
    name: 'youtubeVideos',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingYoutubeVideos: (state, action) => {
            state.loading = true;
        },
        youtubeVideosResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        youtubeVideosClear: (state, action) => {
            state.data = [];
            state.loading = false;
        }
    },
})
export const {gettingYoutubeVideos, youtubeVideosResponse, youtubeVideosClear} = YoutubeVideosSlice.actions;
export default YoutubeVideosSlice.reducer;