import { useEffect, useState} from "react"
import {Row, Col, Skeleton} from "antd"  
import { useDispatch, useSelector} from "react-redux"
import { DashboardCardsData } from "../../Data/data"
import { actionsApi } from "../../shared"
import { DashboardCard } from "./DashboardCard"

const DashboardCards=()=>{

    const dispatch= useDispatch()
    const { statistics } = useSelector(state => state?.dashboard)
    const [state, setState] = useState([])

    useEffect(()=>{
        dispatch(actionsApi?.getStatistics())
    },[])
    useEffect(() => {
        if (statistics) {
          setState([
            { ...DashboardCardsData[0], count: statistics?.totalUsers},
            { ...DashboardCardsData[1], count: statistics?.totalClients },
            { 
              ...DashboardCardsData[2], 
              count1: statistics?.todayAppointments,
              count2: statistics?.tomarrowAppointments,
            },
            { 
              ...DashboardCardsData[3], 
              count1: statistics?.todayPrpsessionCount,
              count2: statistics?.tomarrowPrpsessionCount,
            }
          ])
        }
      }, [statistics])
    return(
        <Row gutter={[16, 24]}>
            {
                !statistics ?
                new Array(4)?.fill(null)?.map((_, index)=>
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={'skelton-card-' + index}>
                        <Skeleton.Button 
                            active 
                            size='large' 
                            shape='square'
                            block
                            style={{width:'100%', height:'72px'}}
                            className='radius-3'
                        />
                    </Col>
                )
                :
                state?.map((data, index) =>
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={'dashboard-card-' + index}>
                        <DashboardCard data={data}/>
                    </Col>
                )
            }
        </Row>
    )
}
export {DashboardCards}