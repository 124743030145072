import {Modal, Typography, Space, Divider, Button, message} from 'antd';
import React, { useEffect, useState} from 'react'
import { LLFormat, convertTo12HourFormat, PRPSession, PRPSessionStatusLookup, actionsApi, formatCurrency} from '../../shared';
import { CitiesRadioGroupWithoutForm } from '../Forms/CitiesRadioGroupWithoutForm'
import { useSelector, useDispatch } from 'react-redux'
import { Addappointment } from '../addAppointment'
const {Title, Text} = Typography

const ViewPRPSessionDetail = ({visible, data, isDashboardPRP,onClose}) => {
    const { PRPappointments} = useSelector(state => state?.allPRPAppointments)
    
    const dispatch= useDispatch()
    const ref = React.createRef();
    const [state, setState]= useState(null)
    useEffect(()=>{
        if(data)
          setState({...data})
    },[data])
    const updatePRPSession= async (status, index)=>{
        let PRPSessions= state?.PRPSessions?.map((session, i)=>{
            if(i===index)
            return ({
                ...session,
                status
            })
            return session
        })
        PRPSessions= PRPSessions?.filter(session => session?.status<4)
        setState({...state, PRPSessions})
        let result= await PRPSession?.updatePRPSession({id: state?.id, PRPSessions})
        if(result)
        {
            dispatch(actionsApi?.PRPappointmentsResponse([...PRPappointments?.map(session =>{
                if(session?.id=== state?.id)
                return ({
                    ...session,
                    PRPSessions
                })
                return session
            })]))
        }
    }
    const [visibleAppointmentModal, setVisibleAppointmentModal] = useState(false)
    const [row, setRow] = useState(null)
    const [editID, setEditID] = useState(null)
    const editAppointment= ()=>{
        onClose()
        setRow({ ...data?.Client_info })
        setEditID(data?.id)
        dispatch(actionsApi?.setSteps({
            ...data,
            client_id: data?.Client_info?.id,
            stage: parseInt(data?.stage),
            clientPriority: parseInt(data?.clientPriority?.id)
        }))
        setVisibleAppointmentModal(true)
    }
  return (
    <div>
        <Modal
            title={
                <Title level={5} className='my-0 text-capitalize'>
                    {
                        state?.Client_info?.name
                    }
                </Title>
            }
            centered
            width={700}
            visible={visible}
            onOk={onClose}
            onCancel={onClose}
            footer={
                <Button 
                    type='primary'
                    onClick={editAppointment}
                    >
                    Edit PRP Session
                </Button>
            }
        >
            <div  ref={ref} className='px-3'>
                <div direction='vertical'>
                    <Space style={{justifyContent: "space-between", width: "100%"}}>
                        <Title level={5} className='m-0'>PRP Sessions:</Title>
                        <Space direction='vertical' className='flex-col-justify-end'>
                            {
                                state?.PRPSessions?.map((session, index)=>
                                    <Space size={20}>
                                        <Text 
                                            key={'session-'+index}
                                            >
                                            {
                                                LLFormat(session?.date)+' - '+ convertTo12HourFormat(session?.time)+" ("+ formatCurrency(session?.cost || 0) +")"
                                            }
                                        </Text>
                                        <CitiesRadioGroupWithoutForm
                                            options={PRPSessionStatusLookup}
                                            value={session?.status}
                                            size='small'
                                            onChange={(e)=> updatePRPSession(e.target.value, index)}
                                            />
                                    </Space>
                                )
                            }
                        </Space>
                    </Space>
                    {
                        state?.note ?
                        <>
                            <Divider/>
                            <Space style={{width: "100%"}} direction='vertical'>
                            <Title level={5} className='m-0'>Note:</Title>
                                <Text>
                                    <span dangerouslySetInnerHTML={{__html: state?.note}}/>
                                </Text>
                            </Space>
                        </>
                        :<></>
                    }
                    <Divider/>
                    <Space style={{justifyContent: "space-between", width: "100%"}}>
                        <Space>
                            <Title level={5} className='m-0'>Total Cost:</Title>
                            <Text>
                                {
                                    formatCurrency(state?.totalPRPCost)
                                }
                            </Text>
                        </Space>
                        <Space>
                            <Title level={5} className='m-0'>Advance:</Title>
                            <Text>
                                {
                                    formatCurrency(state?.advancePRPAmount)
                                }
                            </Text>
                        </Space>
                        <Space>
                            <Title level={5} className='m-0'>Balance:</Title>
                            <Text>
                                {
                                    formatCurrency(state?.balancePRPAmount)
                                }
                            </Text>
                        </Space>
                    </Space>
                </div>
            </div>
        </Modal>
        <Addappointment
            visible={visibleAppointmentModal}
            clientInfo={row}
            editID={editID}
            onClose={() => { setRow(null); setVisibleAppointmentModal(false); setEditID(null) }}
            isPRP
            isDashboardPRP= {isDashboardPRP}
        />
    </div>
  )
}
export {ViewPRPSessionDetail}