import { useRef } from 'react'
import { Modal, Button} from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { PayReceipt } from './PayReceipt'
import { ReadyToPrint } from '../ReadyToPrint'

const PayReceiptModal = ({ visible,viewData, onClose }) => {
    const print= useRef()
    const clickOnPrint= _ => print.current.click() 
    return (
        <div>
            <Modal
                title={'Print Pay Receipt'}
                centered
                width={'793.92px'}
                open={visible}
                onOk={onClose}
                onCancel={onClose}
                footer={
                    <Button
                        type="primary"
                        onClick={clickOnPrint}
                        icon={<PrinterOutlined/>}
                    >
                        Print Pay Receipt
                    </Button>
                }
            >
                <ReadyToPrint
                    name= 'Pay Receipt'
                    print= {print}
                    Component={<PayReceipt/>}
                />
            </Modal>
        </div>
    )
}
export default PayReceiptModal