import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../components';
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd';
export const loadDashboard = createAsyncThunk('Dashboard',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingDashboard())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/index`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(dashboardResponse(result))
                }
                else {
                    dispatch(dashboardClear())
                    throw new Error('error')
                }
            })
            .catch(error => { dispatch(dashboardClear()); Alert("error", "error while loading Dashboard") })
    }
)
export const getStatistics = createAsyncThunk('getStatistics',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingStatistics())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/getStatistics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(statisticsResponse(result))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(statisticsClear())
                message.error(error) 
            })
    }
)
export const getAppointmentStatistics = createAsyncThunk('getAppointmentStatistics',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAppointmentStatistics())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/appointmentStatistics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(appointmentStatisticsResponse(result))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(appointmentStatisticsClear())
                message.error(error) 
            })
    }
)
export const loadFUEAppointmentCalendar = createAsyncThunk('fueAppointmentCalendar',
    async (year, { dispatch }) => {
        const {userToken}= checkAuthorization()
        dispatch(gettingFueAppointmentCalendar())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({year})
        }
        fetch(domainUrl + `/fueAppointmentCalendar`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(fueAppointmentCalendarResponse(result?.data))
                }
                else
                    throw 'error'
            })
            .catch(_ => { 
                dispatch(fueAppointmentCalendarClear()); 
                message.error("error while loading fue appointment calendar") 
            })
    }
)
const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        statistics: null,
        loadingStatistics: false,

        fueAppointmentCalendar:null,
        loadingFueAppointmentCalendar:false,

        appointmentStatistics: null,
        loadingAppointmentStatistics: false,

        data: null,
        loading: false,

        cancelAppointments: 0,
        completeAppointments: 0,
        pendingAppointments: 0,
        todayAppointments: 0,
        totalClient: 0,
        totalUser: 0,
        tomarrowPrpessions: [],
        tomarrowCallAgain: [],
        todayPrpsessions: [],
        todayCallAgain: [],
    },
    reducers: {
        gettingStatistics: state => {
            state.loadingStatistics=true
            state.statistics=null
        },
        statisticsResponse: (state, action)=>{
            state.statistics= action.payload
            state.loadingStatistics= false
        },
        statisticsClear: (state)=>{
            state.statistics= null
            state.loadingStatistics= false
        },
        gettingAppointmentStatistics: state => {
            state.loadingAppointmentStatistics=true
            state.appointmentStatistics=null
        },
        appointmentStatisticsResponse: (state, action)=>{
            state.appointmentStatistics= action.payload
            state.loadingAppointmentStatistics= false
        },
        appointmentStatisticsClear: (state)=>{
            state.appointmentStatistics= null
            state.loadingAppointmentStatistics= false
        },
        gettingFueAppointmentCalendar: state=>{
            state.loadingFueAppointmentCalendar= true
            state.fueAppointmentCalendar= null
        },
        fueAppointmentCalendarResponse: (state, action)=>{
            state.fueAppointmentCalendar = action.payload
            state.loadingFueAppointmentCalendar= false
        },
        fueAppointmentCalendarClear: (state)=>{
            state.fueAppointmentCalendar = null
            state.loadingFueAppointmentCalendar= false
        },

        gettingDashboard: (state) => {
            state.data = null
            state.loading = true
        },
        dashboardResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.cancelAppointments = action.payload.cancelAppointments;
            state.completeAppointments = action.payload.completeAppointments;
            state.pendingAppointments = action.payload.pendingAppointments;
            state.todayAppointments = action.payload.todayAppointments;
            state.totalClient = action.payload.totalClient;
            state.totalUser = action.payload.totalUser;
            state.tomarrowCallAgain = action.payload.tomarrowCallAgain;
            state.tomarrowPrpessions = action.payload.tomarrowCallAgain;
            state.todayCallAgain = action.payload.todayCallAgain;
            state.todayPrpsessions = action.payload.todayPrpsessions;
        },
        dashboardClear: (state) => {
            state.data = null;
            state.loading = false;
        }
    },
})
export const { 
    gettingFueAppointmentCalendar, fueAppointmentCalendarResponse, fueAppointmentCalendarClear,
    gettingStatistics, statisticsResponse, statisticsClear,
    gettingAppointmentStatistics, appointmentStatisticsClear, appointmentStatisticsResponse,
    gettingDashboard, dashboardResponse, dashboardClear
} = DashboardSlice.actions;
export default DashboardSlice.reducer;