import { Typography, Space, Input, Form, Row, Col } from 'antd'
import { CitiesSelect } from '../../'
import { LLFormatWithTime} from '../../../shared'
import './index.css'

const { Text } = Typography
const HairForm = ({state, form, medicinesLookup, handleChange, day}) => {
    
    console.log("state:", state)
  return (
    <>
        <Form 
            layout="vertical"
            form={form}
            initialValues={{
                remember: true,
            }}
            className='prescribed-medicines-form'
        >
            <Row gutter={16}>
                <Col span={12}>
                    <CitiesSelect 
                        mode='multiple'
                        name="medicines"
                        label="Medicines" 
                        required 
                        message='please select atleast one medicine' 
                        placeholder='e.g Cap Cefspan 400 mg' 
                        onChange={(value)=> handleChange('medicines', value)}
                        options={medicinesLookup}
                    />
                </Col>
            </Row>
        </Form>
        <div className='justify-center w-100'>
            <div className='prescribed-medicines-page width-100'>
                <header></header>
                <div>
                    <Space className='width-100' direction='vertical' size={8}>
                        <div className='space-between client-info width-100'> 
                            <div className='space-d' style={{width:'300px'}}>
                                <Text>
                                    Name
                                </Text>
                                <Input 
                                    size='small'
                                    value={state?.name}
                                    />
                            </div>
                            <div className='space-d' style={{width:'100px'}}>
                                <Text>
                                    Age
                                </Text>
                                <Input 
                                    size='small'
                                    value={state?.age}
                                    />
                            </div>
                            <div className='space-d' style={{width:'270px'}}>
                                <Text>
                                    Date
                                </Text>
                                <Input 
                                    size='small'
                                    value={LLFormatWithTime()}
                                    />
                            </div>
                        </div>
                        <ul className='prescribed-medicines'>
                            {
                                state?.medicines?.map((medicine, index)=>
                                <li key={index}>
                                    <div>
                                        <Text>
                                            {
                                                medicine?.name
                                            }
                                        </Text>
                                        <Input 
                                            value={medicine?.intakeMethod}
                                            onChange={(e)=> handleChange('intakeMethod', e.target.value, index)}
                                            />
                                    </div>
                                </li>
                                )
                            }
                        </ul>
                        {/* {
                            day ===1 ?
                            <div className='justify-end'>
                                <span className='font-family-urdu end-note'>
                                    چار دن کے بعد یہ دوائی شروع کرنی ہے۔    
                                </span>
                            </div>
                            :''
                        } */}
                    </Space>
                </div>
                <footer></footer>
            </div>
        </div>
    </>
  )
}

export {HairForm}